/*

Member Portal Dash

Steve Villanueva - 06/06/2024

Mobile, SD, HD

LOB COLOR-SETS
lob_mc.css (Medi-Cal | Sapphire Blue)
lob_oc.css (One Care | Leaf Green)
lob_pc.css (Pace     | Plum Purple)

FOOTER
footer.css

FONT AWESOME
fontawesome/css/fontawesome.css
fontawesome/css/duotone.css

Table of Contents

- Carousel 
- Branding Colors
- Structural
- Navigation
- Accordion

*/

html
{
    min-width:430px;
}

body
{
          font-family: 'Noto Sans';
          min-width: 430px;
}

/* NOTO SANS ONLY!!! */

@font-face
{
  font-family: 'Noto Sans';
  src: url(./fonts/NotoSans-Regular.ttf);
}

@font-face
{
  font-family: 'Noto Sans Light';
  src: url(./fonts/NotoSans-Light.ttf);
}

@font-face
{
  font-family: 'Noto Sans Bold';
  src: url(./fonts/NotoSans-Bold.ttf);
}

/* Carousel */

.d-md-block
{
    display:block !important;
}

.carousel-inner
{
    max-height:300px;
}

.carousel-caption
{
    text-align:left;
    top:40px;
    right: 10%;
    bottom: 1.25rem;
    left: 520px;
    padding-top: 0;
    padding-bottom: 100px;
}

.carousel-title
{

    color:#fff;
    font-size:30px;
    text-shadow: 2px 2px rgba(0,0,0,0.15);
    text-transform: uppercase;
}

.carousel-text
{
    color:#fff;
    font-size:18px;
    text-shadow: 2px 2px rgba(0,0,0,0.15);
    margin-bottom:12px;
}

.carousel-button
{
    background: none;
    margin: 0;
    font-size: 18px;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    -o-transition: all .1s ease-out;
    transition: all .1s ease-out;
    cursor:pointer;
    border-radius:5px;
    padding: 0.375rem 0.75rem;
    background-color:rgba(255,255,255,0.3);
}

button.carousel-button:hover
{
    -webkit-transition: all .1s ease-in;
    -moz-transition: all .1s ease-in;
    -o-transition: all .1s ease-in;
    transition: all .1s ease-in;
}

@media (max-width: 1100px) {

    .carousel-caption
    {
        top:40px;
        left:360px;
    }

    .carousel-title
    {
        font-size:18px;
    }

    .carousel-text
    {
        font-size:16px;
    }

    .carousel-inner
    {
        max-height:240px;
    }

    .carousel-inner img
    {
        max-height:240px;
        margin-left:-40px;
    }
    
}

@media (max-width: 720px) {

    .carousel-caption
    {
        left:300px;
    }

    .carousel-title
    {
        font-size:18px;
    }

    .carousel-text
    {
        font-size:14px;
    }

    .carousel-button
    {
        font-size:16px;
    }

    .carousel-inner
    {
        max-height:240px;
    }

    .carousel-inner img
    {
        max-height:240px;
        margin-left:-100px;
    }
    
}

@media (max-width: 640px) {

    .carousel-caption
    {
        left:260px;
        top:20px;
    }

    .carousel-title
    {
        margin-bottom:20px;
    }

    .carousel-text
    {
        display:none;
    }

    .carousel-inner
    {
    }

    .carousel-inner img
    {
        margin-left:-140px;
    }
    
}

/* Branding Colors */

.bg-tangerine
{
    background-color:#F05023;
}

.bg-sky
{
    background-color:#00B2E2;
}

.bg-ruby
{
    background-color:#E7194F;
}

.bg-sunflower
{
    background-color:#F99C24;
}

.bg-sapphire
{
    background-color:#0071B9;
}

.bg-leaf
{
    background-color:#7BC24E;
}

.bg-plum
{
    background-color:#9B278F;
}

.bg-graphite
{
    background-color:#5B6770;
}

/* Structural */

.row-height-100, .col-height-100
{
    height:100%;
}

.member-wrapper
{
    height:100%;
    width:100%;
    float:left;
    text-align:left;
}

.member-fa-wrapper
{
    height:100%;
    float:left;
    font-size:32px;
    padding:10px 20px 0 0;
}

.member-name-wrapper
{
    height:100%;
    float:left;
}

.member-name
{
    font-size:26px;
    overflow-wrap:break-all;
}

.member-cin
{
    color:gray;
    padding-bottom:5px;
}

.member-profile-link
{
    font-size:14px;
}

.member-profile-details
{
    height:100%;
    float:left;
    font-size:14px;
}

.member-profile-data
{
    padding-bottom:8px;
}

.member-separator
{
    height:10px;
    width:100%;
    border-bottom:1px solid rgba(200,200,200,1);
    display:inline-block;
}

.card-accordion-wrapper 
{
    background-color:#fff;
}

.card-wrapper
{
    padding:40px 100px 40px 100px;
}

.cta-wrapper
{
    padding: 40px 100px 40px 100px;
}

.cta-mini-wrap
{
    display:flex;
    height:100%;
}

.cta-mini-left
{
    margin-right:20px;
}

.cta-mini-image
{
    height:100%;
    margin:10px 0 10px 10px;
}

.cta-icon-wrapper
{
    font-size:32px;
}

@media (max-width:1400px) {
    .cta-mini-image
    {
        height:auto;
    }
}

.cta-mini-right
{
    flex-grow:1;
    margin-top:10px;
}

@media (max-width: 1250px) {

    .cta-mini-wrap
    {
        display:inline-block;
        text-align:center;
    }

    .cta-mini-left
    {
        margin-bottom:20px;
    }

}

.container-fluid img
{
    height:50px;
}

.msg-wrapper
{
    padding: 40px 100px 40px 100px;
}

.msg-toggle
{
    text-align:right;
    padding-right:15px;
}


.col-md-4-left
{
    padding:10px;
    height:100%;
}

.col-md-4-right
{
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:60px;
}

.card
{
    border-radius: 10px;
    background-color: rgba(244,244,244,0.75);
    border:1px solid #e3e3e3;
    height:100%;
}

.card-cta-left 
{
    background-color:#fff;
    text-align:left;
    border-top-left-radius:80px;
    border-top-right-radius:10px;
    border-bottom-left-radius:10px;
    border-top-right-radius:10px;
    overflow:hidden;
    height:100%;
}

.card-cta-right
{
    background-color:#fff;
    text-align:left;
    border-radius:10px 10px 10px 80px;
    overflow:hidden;
    height:100%;
}

.card-paddit
{
    padding:10px;
}



/* Navigation */

.fa-nav-lob
{
    font-size:24px;
}

.nav-msg
{
    margin-top:10px;
    margin-right:20px;
    z-index:2;
}

.msg-notifier
{
    padding-top:7px;
    border:none;
    background-color:rgba(255,255,255,0);
}

nav.bg-primary
{
    z-index:1;
    opacity:0.9;
    position:sticky;
    top:0;
}

.nav-item
{
    margin-left:20px;
}

.navbar-toggler:focus
{
    box-shadow: none;
}

.dropdown-menu
{
    margin-left:-100px;
}

@media (max-width: 991px) {
    
    .dropdown-menu
    {
        margin-left:0;
    }

    .nav-link
    {
        text-align:right;
    }

}

nav.bg-primary
{
    border-bottom:1px solid rgba(200,200,200,1);
}

ul,li.nav-item a
{
    color:#2e8ac6;
}

a.dropdown-item
{
    color:#2e8ac6 !important;
}

.display-column
{
    display:flex;
    flex-direction: column;
    flex-basis: 100%;
    flex:2;
}

.card-header
{
    text-align:left;
}

.card-title
{
    font-size:22px;
    font-weight:500;
    color:#000 !important;
}

.card-footer
{
    font-size:14px;
    text-align:left;
}

/* Accordion */

.accordion-button {
  align-items: center;
  width: 100%;
  padding: 0 10px 0 0;
  height:70px;
  color:#0071b9 !important;
}

.dot-unread {
  height: 12px;
  width: 12px;
  background-color: #0071b9; 
  border-radius: 50%;
  display: inline-block;
}

.dot-read {
    height: 12px;
    width: 12px;
    background-color: rgba(255,255,255,0);
    border-radius: 50%;
    display:inline-block;
}

.accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.accordion-status-success
{
    height:100%;
    width:5px;
    background-color:#7BC24E;
}

.accordion-status-important
{
    height:100%;
    width:5px;
    background-color:#d9831f;
}

.accordion-status-general
{
    height:100%;
    width:5px;
    background-color:#0071b9;
}

.accordion-dot
{
    height:100%;
    padding: 15px 10px 0 10px;
    vertical-align: text-top;
}

.accordion-content-wrapper
{
    padding:0;
}

.accordion-subject-unread
{
    font-size:18px;
    font-weight:bold;
    color:#0071b9;
}

.accordion-subject-read
{
    font-size:18px;
    font-weight:normal;
    color:#0071b9;
}

.accordion-content-body
{
    font-size:16px;
    color:#999999;
    padding:3px 3px 3px 0;
}

.accordion-date
{
    font-size:14px;
    color:#999999;
    padding:0 20px 0 0;
    text-align:right;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
  color:#0071b9;
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  color:#0071b9;
}

.accordion-item {
  margin-bottom:5px;
}

.accordion-body {
    text-align:left;
}

.accordion-delete
{
    width:100%;
    height:auto;
    text-align:right;
    padding:0 20px 5px 0;
}

.accordion-delete a
{
    text-decoration:none !important;
    color:#0071B9;
}

.card-min
{
    min-height:190px;
}

@media (max-width: 1250px) {

    .accordion-button
    {
        height:50px;
    }

    .card-wrapper
    {
        padding:60px 20px 60px 20px;
    }

    .cta-wrapper
    {
        padding: 60px 20px 60px 20px;
    }

    .msg-wrapper
    {
        padding: 60px 20px 60px 20px;
    }
    /*
    .col-md-4, .col-md-4-left, .col-md-4-right
    {
        display:none;
    }
    */
    .col-md-8
    {
        width:100%;
    }

    .card-body
    {
        text-align:center;
    }

    .accordion-subject-unread, .accordion-subject-read
    {
        font-size:15px;
    }

    .accordion-content-body
    {
        display:none;
    }

}

/* EOF */












