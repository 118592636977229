
/* LOB:  Medi-Cal */
/* LOB:  Medi-Cal */
/* LOB:  Medi-Cal */


nav.bg-primary
{
    background-color: #e7f1ff !important;
}

.carousel-button
{
    color:#fff;
    border: 1px solid #fff;
    background-color:rgba(255,255,255,0.3);
}

button.carousel-button:hover
{
    color: #fff;
    background: #0087C7;
}

.fa-lob, .fa-nav-lob
{
    --fa-primary-color: #0071B9;
    --fa-secondary-color: #0071B9;
}

.btn-primary
{
    background-color:#0071B9;
    border-color:#0071B9;
    border:1px solid #0071B9;
}

.btn-primary:hover
{
    background-color:#fff;
    color:#0071B9;
}

.cta-wrapper
{
    background-color:#e7f1ff;
}

.card-cta-body
{
    background-color:#e7f1ff!important;
    border:1px solid #bfdaff!important;
}

.card-cta-header
{
    background-color:#bfdaff;
    border:1px solid #bfdaff;
}

.accordion-button:not(.collapsed)
{
    background-color:#e7f1ff;
}


