@charset "UTF-8";
/* -------------------------------------------------------------------------

 CalOptima Style Guide
 Bootstrap Overrides, Base Styles & Patterns for Member Portal
 Authors: Rudy Huebner and Elliot Yamashiro
    ---------------------------------------------------------------------------- */

// Spacelab 3.3.7
// Bootswatch
// -----------------------------------------------------

$web-font-path: "https://fonts.googleapis.com/css?family=Noto+Sans:400italic,700italic,400,700" !default;
@import url($web-font-path);

@mixin btn-shadow($color) {
  @include gradient-vertical-three-colors(
    lighten($color, 15%),
    $color,
    50%,
    darken($color, 4%)
  );
  filter: none;
  border: 1px solid darken($color, 10%);
}

/* -------------------------------------------------------------------------
 Global
---------------------------------------------------------------------------- */

@keyframes ui-progress-spinner-color {
  100%,
  0% {
    stroke: #0087c7;
  }

  50% {
    stroke: #7d2350;
  }
}

.ui-widget-overlay {
  background: whitesmoke;
  opacity: 0.75;
  filter: Alpha(Opacity=75);
}

.ui-progress-spinner-circle {
  stroke: #0087c7;
  stroke-dasharray: 89, 200;
  stroke-dashoffset: -15px;
}

.sv_qstn {
  background-color: #f4f4f4;
  margin: 20px 0 20px 0;
  border: 1px solid #e3e3e3;
  font-family: "Noto Sans", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: bold;
  font-size: 20pt;
  color: #71758b;
}

.sv_qstn2 {
  clear: both;
  background-color: white;
  margin: 20px 0 20px 0;
  border: 1px solid #e3e3e3;
  font-family: "Noto Sans", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: bold;
  font-size: 20pt;
  color: black;
}

.sv_qstn span {
  font-size: 20px;
  font-weight: bold;
  color: #71758b;
}

[role="alert"] {
  span {
    color: #b30000;
    font-size: 14pt;
    font-weight: normal;
    border-color: #eed3d7;
    background-color: #f2dede;
  }
}

.sv_qstn input {
  max-width: 662px;
}

.sv_qstn div select {
  max-width: 662px;
}

.sv_qstn .radio {
  max-width: 662px;
}

.sv_qcbc {
  max-width: 662px;
}

.sv_qcbc span {
  font-size: 13pt;
  font-weight: normal;
  color: black;
}

.sv_bootstrap_css .sv_qstn {
  padding: 20px 20px 20px 20px !important;
}

.sv_qstn .alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #0087c7;
  font-size: 14pt;
  font-weight: normal;
  overflow-wrap: break-word;
}

.progress {
  height: 47px;
  width: 448px;
  border: 0px;
  background-color: #efefef;
}

.progress-bar {
  border: 0px;
  background-color: #7fc3e3;
  font-size: 12pt;
  font-weight: normal;
  text-shadow: 1px 1px 2px #565656;
  padding: 0px 0px;
  height: 48px;
  white-space: nowrap;
  vertical-align: middle;
  color: white;
}

.progress-bar span {
  border: 0px;
}

.incomplete-msg {
  font-size: 12pt;
}

.sv-title {
  color: #333 !important;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
}

.sv-progress {
  height: 0.5em !important;
  background-color: #e7e7e7;
  position: relative;
}

// Question container
// .sd-element--with-frame {
//   padding-bottom: 4px !important;
// }

.sd-page .sd-page__title {
  font-weight: 400 !important;
  font-size: 24px !important;
  color: #0087c7 !important;
}

.sd-title .sv-title-actions {
  padding-left: 10px !important;
}

.sd-container-modern {
  background-color: #efefef !important;
}

// CUSTOM SIZES FOR PROGRESS BUTTONS
$progress-width: 24px !important;
$progess-height: 24px !important;

.sd-progress-buttons__page-title {
  justify-content: left !important;
  font-weight: bold !important;
}

.sd-progress-buttons__list-container {
  overflow: unset !important;
  margin: 0 !important;
  max-height: 30px !important;
}

.sd-progress-buttons__list .sd-progress-buttons__list-element--current:after {
  width: $progress-width;
  height: $progess-height;
}

.sd-progress-buttons__list li:not(:first-child) > .sd-progress-buttons__connector {
  height: 4px !important;
  margin: 3px 0 !important; // Adjust to align the progress-bar line
}

.sd-progress-buttons__list li:after {
  width: $progress-width;
  height: $progess-height;
  margin: 0 !important;
}

.sd-progress-buttons__button {
  margin: 0 !important;
  width: $progress-width;
  height: $progess-height;
  padding: 0 !important;
}

.sd-progress-buttons__button-background {
  background-color: transparent !important;
  border: none !important;
}

.sd-progress-buttons__button-content {
  background-color: #aba9a9 !important;
  opacity: 1 !important;
  width: $progress-width;
  height: $progess-height;
}

.sd-progress-buttons__list-element--current .sd-progress-buttons__button-content {
  background-color: #fff !important;
}

.sd-progress-buttons__list-element--passed .sd-progress-buttons__button-content {
  background-color: rgba(37, 137, 229, 1) !important;
}

.sd-body.sd-body--static {
  max-width: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 30px !important;
}

.sd-question > div {
  margin-left: 25px;
}

// .sd-question__content {
//   margin: 0 !important;
// }

// Question Text
.sd-element__title .sd-element__num {
  margin-right: 20px !important;
  display:flex;
  justify-content:center;
}

// .sd-element__title .sv-title-actions__title {
//   margin-left: 10px !important;
// }

@media (min-width: 768px) {
  .sd-selectbase {
    margin-left: 20px !important;
  }
}

.sv-question__num, .sd-element__num {
  border-radius: 100% !important;
  width: 42px !important;
  height: 42px !important;
  background: #ffffff;
  border: 3px solid #0087c7;
  font-family: "Noto Sans";
  font-size: 26px !important;
  font-weight: bold;
  color: #0087c7;
  padding: 0 4px 4px 2px;
  display: inline-block;
  text-align: -webkit-center !important;
  text-overflow: clip;
  padding-top: 9px !important;
  padding-inline-end: 0 !important;
}

.sd-element__title .sv-string-viewer {
  vertical-align: middle !important;
}

.sd-input.sd-input--disabled {
  opacity: .50 !important;
  max-width: 98% !important;
}

.sd-item--disabled .sd-item__control-label {
  opacity: .50 !important;
}

.sd-radio--checked.sd-radio--disabled .sd-radio__decorator:after {
  background-color: #909090 !important;
}

.sd-checkbox--checked.sd-checkbox--disabled .sd-checkbox__svg use {
  fill: #909090 !important;
}

.sv-button-container {
  display: block;
  padding-top: 20px;
  width: 100%;
}

.sv-button {
  min-width: 100px;
  margin: 8px;
  padding: 8px;
}

.sv-button-right  {
  display: inline-flex;
  float: right;
  margin-right: -12px;
  width: auto;
}

.sv-button-left {
  padding-left: 20px;
  display: inline-flex;
  float: left;
  width: auto;
}

.sv-radio__svg {
  border-radius: 100% !important;
}

.healthappraisalbtn {
  min-width: 100px;
  margin: 8px;
  padding: 8px;
}

.healthappraisalbtns {
  display: inline;
}

.healthappraisalposition {
  float: right;
}

body {
  margin: 0;
  font-family: "Noto Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 12pt;
  line-height: 1.42857;
  // background-image: url('../assets/images/u4.jpg');
  // background-repeat: no-repeat;
  // background-position: center;
}

// * {
//   border-radius: 0 !important;
// }

// All elements but NOT this selector (*)
// :not(.sd-item__decorator, 
// .sd-element__num, 
// .sd-progress-buttons__button,
// .sd-progress-buttons__button-content,
// .sd-progress-buttons__button-background) {
//   border-radius: 0 !important;
// }

// .navbar {
//   text-shadow: none !important;
// }

a {
  color: #00365b;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #0087c7;
  text-decoration: underline;
}

a:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

// Header =====================================================================

#header-greeting {
  font-size: 22px;
}

#program-spacing,
#network-spacing,
#eligibility-spacing,
#doctor-spacing {
  padding-bottom: 0px;
}

#header-program,
#header-network,
#header-eligibility,
#header-doctor,
#header-program-name,
#header-network-name,
#header-eligibility-status,
#header-doctor-name {
  color: #333;
  font-size: 14px;
  padding-top: 0px;
  padding-right: 0px;
  white-space: nowrap;
}

.masthead_area {
  position: relative;
  padding-top: 80px;
  margin-bottom: 10px;
}

.copyright {
  padding: 20px 0;
  font-size: 9pt;
}

.panel {
  border: 0 !important;

  &-primary {
    .panel-title {
      color: #fff;
    }
  }
}

/* -------------------------------------------------------------------------
 Master Layout
---------------------------------------------------------------------------- */
// #masthead-content {
//   padding: 0;
//   overflow-x: hidden;
//   overflow-y: hidden;
// }

// @media (min-width: 1199px) {
//   #masthead-content {
//     height: 110px;
//   }
// }

@media (max-width:1199px)
{
  .navbar-nav .dropdown-menu {
    position: static;
    text-align: right;
    
  }
}

#middle-content {
  padding-top: 20px;
  padding-bottom: 30px;
}

#footer-content {
  text-align: center;
}

#inquiryText {
  min-height: 100px;
}

@media (max-width: 1000px) {
  .centered-content {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .centered-content {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

/* -------------------------------------------------------------------------
 Section Page
---------------------------------------------------------------------------- */

/*#main-content {}*/

@media (min-width: 1200px) {
  .csfooter {
    width: 1110px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .csfooter {
    width: 910px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 991px) {
  .csfooter {
    margin-top: 30px;
    margin-right: 5%;
    margin-left: 5%;
  }
}

.csfooter {
  border-top: 1px solid #dadada;
  margin-bottom: 30px;
  padding-top: 30px;
  text-align: center;
}

.csfooter h3 {
  color: #00365b;
  font-weight: bold;
  margin-top: 5px;
}

.csfooter h4 {
  color: #71758b;
  margin-top: 10px;
  font-size: 18px;
}

.csfooterspacer {
  margin-top: 30px;
}

.csfooter .primary {
  color: #00365b;
}

.csfooter .csaddress {
  color: #00365b;
  font-weight: bold;
  margin-bottom: 0;
}

/* -------------------------------------------------------------------------
 Masthead
---------------------------------------------------------------------------- */

#masthead {
  width: 100%;
}

/* START Updated classes */
.navbar-toggler {
  color: #fff;
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}

.dropdown-item {
  white-space: nowrap;
  width: 100%;
}

.nav-member .navbar-default {
  background-color: transparent;
}

.container-fluid {
  // padding: 0;
  // padding-bottom: 0px;
  padding-left: 0;
}

.carousel-caption {
  z-index: 0 !important;
}
// #caloptima-carousel {
//   top: -20px;
// }

.nav-member .container-fluid {
  padding: 10px 0 0 0;
}

.nav-member .navbar-nav > li > a {
  color: #00365b !important;
  padding: 0;
}

.memname {
  font-size: 18pt;
}

@media (min-width: 992px) and (max-width: 1000px) {
  #top-nav-area {
    padding: 5px 60px 10px 60px;
    left: 5px;
  }

  .container-fluid {
    padding-right: var(--bs-gutter-x, 0.75rem);
  }
}

@media (min-width: 1001px) {
  #top-nav-area {
    padding: 10px 60px;
  }
}

@media (min-width: 992px) {
  #top-nav-area {
    display: flex;
    justify-content: flex-end;
  }

  .topnav-item {
    padding-right: 30px;
  }

  #middle-content {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  // .navbar {
  //   padding-left: 20px;
  // }

  // .navbar-stretch {
  //   background: transparent url(../assets/images/NavBG.png) top left no-repeat;
  // }

  // .navbar > .container {
  //   max-width: none;
  //   padding-right: 100px;
  // }

  .logo {
    margin: 10px 0 0 0;
    clear: both;
  }

  .nav-member {
    margin-left: 0px;
    margin-right: 0px;
    width: 600px;
  }

  /* Positioning of header content */
  #header-program {
    list-style-type: none;
    left: -40px;
  }

  #header-network {
    display: inline-block;
    left: 13%;
    white-space: nowrap;
  }

  #header-eligibility {
    list-style-type: none;
    top: 20px;
    left: -434px;
  }

  #header-doctor {
    display: inline-block;
    top: 2px;
    left: 274px;
  }
}

@media (max-width: 991px) {
  // .navbar-stretch {
  //   background: transparent url(../assets/images/NavBG.png) top left no-repeat;
  // }

  #top-nav-area {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }

  #middle-content {
    padding-top: 0px;
    padding-bottom: 30px;
  }

  .logo {
    display: block;
    margin: 0 auto;
    clear: both;
  }

  #navbar-default {
    position: relative;
    width: 100%;
    height: 35px;
  }

  #navbar-short {
    position: absolute;
    background-color: #fff;
    top: 0;
    height: 18px;
    width: calc(100% - 85px);
  }

  #header-program,
  #header-network,
  #header-eligibility,
  #header-doctor {
    list-style-type: none;
  }



  // .navbar-mega {
  //   margin-top: 0;
  // }

  // .navbar-collapse {
  //   flex-basis: 0;
  //   flex-grow: 0;
  //   float: none;
  //   align-items: none;
  // }
}

// @media (min-width: 768px) {
//   .navbar-expand-md > .container,
//   .navbar-expand-md > .container-fluid {
//     -ms-flex-wrap: nowrap;
//     flex-wrap: nowrap;
//     margin: 0;
//   }
// }

// @media (min-width: 1525px) {
//   // .navbar-expand-custom {
//   //   flex-direction: row;
//   //   flex-wrap: nowrap;
//   //   justify-content: flex-start;
//   // }

//   // .navbar-expand-custom .navbar-nav {
//   //   flex-direction: row;
//   // }

//   // .navbar-expand-custom .navbar-nav .nav-link {
//   //   padding-right: 0.5rem;
//   //   padding-left: 0.5rem;
//   // }

//   // .navbar-expand-custom .navbar-collapse {
//   //   display: flex !important;
//   // }

//   // .navbar-expand-custom .navbar-toggler {
//   //   display: none;
//   // }
// }
/* END Updated classes */

@media (min-width: 992px) {
  #logo-area {
    left: 15px;
  }
}

#logo {
  width: 220px;
  height: 64px;
  margin: 20px 0 0 60px;
  background: url(../assets/images/CalOptimaLogo.png) no-repeat center center;
  clear: both;
}

#navbar {
  width: 100%;
  height: 35px;
  background: url(../assets/images/NavBG.png);
}

#over {
  width: 405px;
}

#overmask {
  width: 100%;
  height: 35px;
  background: url(../assets/images/OverlayBG.png);
}

/*   */
/* CSS Document */

/**********************************************************************************************************
Body and structural id tags
***********************************************************************************************************/

/**********************************************************************************************************
GLOBAL Color
These classes are divs used for preseational purposes only, such as spacers or float clearing.
***********************************************************************************************************/

/**********************************************************************************************************
 Color Overrides
***********************************************************************************************************/

a {
  color: #007db8;
}

a:hover,
a:active {
  color: #00365b;
}

.bg-custom {
  background-color: transparent !important;
}

.bg-secondary {
  background-color: #007db8 !important;
}

/**********************************************************************************************************
  Custom Colors
***********************************************************************************************************/

.brandblue {
  color: #00365b !important;
}

.brandgray {
  color: #6c757d !important;
}

.brandberry {
  color: #7d2350 !important;
}

.brandmcal {
  color: #0083bf !important;
}
.brandmcal_bg {
  background-color: rgba(0, 131, 191, 0.1) !important;
}
.brandocc {
  color: #339540 !important;
}
.brandocc_bg {
  background-color: rgba(58, 175, 74, 0.1) !important;
}
.brandpace {
  color: #74308c !important;
}
.brandpace_bg {
  background-color: rgba(116, 48, 140, 0.1) !important;
}

.text-lighter {
  color: #a2a2a2;
}

.text-primary {
  color: #00365b !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #0087c7 !important;
}

.text-secondary {
  color: #0087c7 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #00365b !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #0087c7 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #0087c7 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #a71d2a !important;
}

.asterisk {
  color: red;
}

.errorMessage {
  color: red;
}

/**********************************************************************************************************
CalOptima Custom Overrides
***********************************************************************************************************/

/*
 * Mega Menu
 * --------------------------------------------------
 */
.navbar-login {
  padding-right: 10px;
}

// .navbar-nav .dropdown-menu {
//   background-color: rgba(255, 255, 255, 0.95);
//   border-top: 0px;
// }

// .navbar-nav {
//   padding: 0px;
//   margin: 0px;
// }

// .navbar-light {
//   color: #ffffff;
//   background-color: #ccc6c6;
//   border-color: #aca1a2;
// }

// .navbar-light .navbar-nav .nav-link {
//   color: #ffffff;
// }

// .navbar-light .navbar-nav .nav-link:hover,
// .navbar-light .navbar-nav .nav-link:focus {
//   color: #dadada;
// }

// .navbar-light .navbar-nav .show > .nav-link,
// .navbar-light .navbar-nav .active > .nav-link,
// .navbar-light .navbar-nav .nav-link.show,
// .navbar-light .navbar-nav .nav-link.active {
//   color: #ffffff;
// }

// .navbar-light .navbar-nav > li > a {
//   color: #ffffff;
// }

// .navbar-light .navbar-nav > .dropdown > a .caret {
//   border-top-color: #ffffff;
//   border-bottom-color: #ffffff;
// }

// .navbar-light .navbar-brand {
//   color: #ffffff;
// }

// .navbar-mega .nav-link {
//   color: #ffffff;
//   padding-right: 30px;
// }

.menu-large {
  position: static;
}

.topnav-top {
  margin-top: 27px;
}

.dropdown-item a {
  color: #333333;
}

.megamenu {
  padding: 20px 20px;
  width: 100%;
  margin: 0;
}

.megamenu > div > li > ul {
  padding: 0;
  margin: 0;
}

.megamenu > div > li > ul > li {
  list-style: none;
}

.megamenu .card {
  outline: none;
}

.megamenu .card:hover,
.megamenu .card:focus {
  outline: 1px solid #000000;
}

.megamenu > div > li > ul > li > a {
  display: block;
  padding: 3px 0;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #333333;
  white-space: normal;
}

.megamenu > div > li > ul > li > a:hover,
.megamenu > div > li > ul > li > a:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}

.megamenu.disabled > a,
.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
  color: #999999;
}

.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  // filter: progid: DXImageTransform.Microsoft.gradient(enabled=false);
  cursor: not-allowed;
}

.megamenu .dropdown-header {
  padding: 0 0 10px 0;
  font-size: 14pt;
}

.megamenu .dropdown-header a {
  text-decoration: none;
}

.megamenu .dropdown-item > ul {
  list-style-type: none;
}

.navtext {
  padding: 20px 0;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #16181b;
  text-decoration: none;
  background-color: transparent !important;
}

.mmchild {
  text-decoration: none;
  cursor: default;
}

a.mmchild,
a.mmchild:hover,
a.mmchild:visited,
a.mmchild:active {
  text-decoration: none;
}

.megaspacer {
  margin-bottom: 24px;
}

// @media (min-width: 992px) {
//   .navbar-expand-lg .navbar-nav .nav-link {
//     color: #ffffff;
//     padding-right: 30px;
//   }
// }

// @media (max-width: 991px) {
//   .navbar p {
//     width: 85%;
//   }

//   .navbar-light .navbar-brand {
//     max-width: 200px;
//   }

//   .col-md-3 {
//     max-width: 100%;
//   }

//   .navbar-mega .nav-link {
//     color: #00365b;
//   }

//   .navbar-light .navbar-nav .nav-link {
//     color: #00365b;
//   }

//   .navbar-light .navbar-nav .nav-link:hover,
//   .navbar-light .navbar-nav .nav-link:focus {
//     color: #5b5b5b;
//   }

//   .navbar-light .navbar-nav .show > .nav-link,
//   .navbar-light .navbar-nav .active > .nav-link,
//   .navbar-light .navbar-nav .nav-link.show,
//   .navbar-light .navbar-nav .nav-link.active {
//     color: #00365b;
//   }

//   .megamenu {
//     margin-left: 0;
//     margin-right: 0;
//   }

//   .megamenu > li {
//     margin-bottom: 30px;
//   }

//   .megamenu > li:last-child {
//     margin-bottom: 0;
//   }

//   .megamenu .dropdown-header {
//     font-size: 1rem;
//   }

//   .navbar-nav .open .dropdown-menu .dropdown-header {
//     color: #ffffff;
//   }

//   .dropdown-header {
//     min-width: 120px;
//   }

//   .megaspacer {
//     margin-bottom: 0;
//   }
// }

// .navbar-light .navbar-toggler {
//   background-color: rgba(248, 248, 248, 0.5);
// }

@media (max-width: 991px) {
  .md-drop-list {
    display: none;
  }
}

// @media (min-width: 768px) {
//   .navbar p {
//     width: 94%;
//   }

//   .navbar-expand-md > .container,
//   .navbar-expand-md > .container-fluid {
//     -ms-flex-wrap: nowrap;
//     flex-wrap: nowrap;
//     margin: 0;
//   }
// }
/*
 * Off Canvas
 * --------------------------------------------------
 */
 .offcanvas.offcanvas-end{
  top: 65px;
 }

.offcanvas {
flex-grow: 0!important;
}
.offcanvas-backdrop.show {
  opacity: .0;
}
// Menu before login ================================================
app-user-header .navbar-nav .dropdown-menu{
  position: static;
  text-align: right;
  border: 1px solid rgba(0, 0, 0, .15);
}
app-user-header .fa-lob, 
app-user-header  .fa-nav-lob, 
app-user-header a
{  
  color: white!important;
  --fa-primary-color:white!important;
}

.dropdown-menu .dropdown-item {
  color: #2e8ac6;
}

@media (max-width: 991px) {
  #header-program,
  #header-network,
  #header-eligibility,
  #header-doctor,
  #header-program-name,
  #program-spacing > ul {
    padding-left: 0px;
  }

  .lang-menu-white a {
    color: white !important;
  }

  .lang-menu-white:hover a {
    opacity: 0.7;
  }

 

  .masthead_area {
    padding-top: 70px;
  }
}
.nav-scroller {
  position: relative;
  z-index: 120;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, .75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-underline .nav-link {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: .875rem;
  color: #6c757d;
}

.nav-underline .nav-link:hover {
  color: #007bff;
}

.nav-underline .active {
  font-weight: 500;
  color: #343a40;
}

.bg-purple { background-color: #6f42c1; }

.lh-100 { line-height: 1; }
.lh-125 { line-height: 1.25; }
.lh-150 { line-height: 1.5; }
 
/*
 * Transparent Sticky Nav
 * --------------------------------------------------
 */

.navbar {
  background-color: rgba(0, 113, 185, 0.8);
  border: none;
  color: white;
  z-index: 130;
  transition: background-color 1s ease 0s;
}
/* Solid class attached on scroll past first section */
.navbar.solid {
  background-color: rgba(0, 113, 185, 0.8);
  transition: background-color 1s ease 0s;
  box-shadow: none;
}

.navbar.solid .navbar-brand {
  transition: color 1s ease 0s;
}

.navbar.solid .navbar-nav > li > a {
  opacity: 1;
  filter: alpha(opacity=100); /* For IE8 and earlier */
  transition: color 1s ease 0s;
}
/*
 * Alerts
 * --------------------------------------------------
 */
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 0;
}

.alert-das {
  padding: 15px;
  margin-bottom:0; 
  position:sticky; 
  display: flex; 
  align-items: center; 
  gap: 10px;
}

.alert-das > i {
  --fa-primary-color: #000000; 
  --fa-secondary-color: #d9831f;
}

.alert h4 {
  margin-top: 0;
  color: inherit;
}

.alert .alert-link {
  font-weight: bold;
}

.alert > p,
.alert > ul {
  margin-bottom: 0;
}

.alert > p + p {
  margin-top: 5px;
}

.alert-dismissable {
  padding-right: 35px;
}

.alert-dismissable .close {
  position: absolute;
  top: 2px;
  right: 21px;
  color: inherit;
}

.alert-success {
  background-color: rgba(70, 148, 8, 0.08);
  border-color: #d6e9c6;
  color: #469408;
}

.alert-success hr {
  border-top-color: #c9e2b3;
}

.alert-success .alert-link {
  color: #356635;
}

.alert-info {
  background-color: rgba(0, 135, 199, 0.08);
  border-color: #bce8f1 !important;
  color: #0087c7 !important;
}

.alert-info hr {
  border-top-color: #a6e1ec;
}

.alert-info .alert-link {
  color: #2d6987;
}

.alert.alert-info {
  margin-top: 0 !important;
}

.alert-message {
  color: #664d03;
}

.alert-warning {
  background-color: #fff3cd;
  border-color: #ffecb5;
  color: #d9831f;
}

.alert-warning hr {
  border-top-color: #f8e5be;
}

.alert-warning .alert-link {
  color: #a47e3c;
}

.alert-danger {
  background-color: rgba(179, 0, 0, 0.08);
  border-color: #eed3d7;
  color: #b30000;
}

.alert-danger hr {
  border-top-color: #e6c1c7;
}

.alert-danger .alert-link {
  color: #953b39;
}

.alert-error {
  background-color: rgba(179, 0, 0, 0.08);
  border-color: #eed3d7;
  color: #b30000;
}

.alert-error hr {
  border-top-color: #e6c1c7;
}

.alert-error .alert-link {
  color: #953b39;
}

.livestream {
  margin-top: 30px;
}

.livestreamlink {
  font-weight: bold;
  color: red;
}
/*
 * Breadcrumb
 * --------------------------------------------------
 */

.breadcrumb {
  background-color: transparent;
  padding: 2px 0;
  color: #6c757d;
  font-size: 12pt;
}

// .breadcrumb-item,
// .breadcrumb-item.active {
// }

// .breadcrumb-item + .breadcrumb-item::before {
// }
/*
 * Buttons
 * --------------------------------------------------
 */
.btn-primary {
  color: #ffffff;
  background: #00365b; /* For browsers that do not support gradients */
  background: -webkit-radial-gradient(
    #49708f 2%,
    #00365b
  ); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(
    #49708f 2%,
    #00365b
  ); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(
    #49708f 2%,
    #00365b
  ); /* For Firefox 3.6 to 15 */
  background: radial-gradient(
    #49708f 2%,
    #00365b
  ); /* Standard syntax (must be last) */
  border-color: #00365b;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #ffffff;
  background: #0087c7;
  background: -webkit-radial-gradient(#0087c7, #0087c7); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(#0087c7, #0087c7); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(
    #0087c7,
    #0087c7
  ); /* For Firefox 3.6 to 15 */
  background: radial-gradient(
    #0087c7,
    #0087c7
  ); /* Standard syntax (must be last) */
  border-color: #0087c7;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #aeaeae;
  background: -webkit-radial-gradient(
    #aeaeae 2%,
    #aeaeae
  ); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(
    #aeaeae 2%,
    #aeaeae
  ); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(
    #aeaeae 2%,
    #aeaeae
  ); /* For Firefox 3.6 to 15 */
  background: radial-gradient(
    #aeaeae 2%,
    #aeaeae
  ); /* Standard syntax (must be last) */
  border-color: #aeaeae;
}

.btn-primary .badge {
  color: #00365b;
  background-color: #ffffff;
}

.btn-secondary {
  color: #ffffff;
  background: #0087c7; /* For browsers that do not support gradients */
  background: -webkit-radial-gradient(
    #5fa6d1 2%,
    #0087c7
  ); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(
    #5fa6d1 2%,
    #0087c7
  ); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(
    #5fa6d1 2%,
    #0087c7
  ); /* For Firefox 3.6 to 15 */
  background: radial-gradient(
    #5fa6d1 2%,
    #0087c7
  ); /* Standard syntax (must be last) */
  border-color: #0087c7;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active,
.open .dropdown-toggle.btn-secondary {
  color: #ffffff;
  background: #00365b;
  background: -webkit-radial-gradient(#00365b, #00365b); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(#00365b, #00365b); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(
    #00365b,
    #00365b
  ); /* For Firefox 3.6 to 15 */
  background: radial-gradient(
    #00365b,
    #00365b
  ); /* Standard syntax (must be last) */
  border-color: #00365b;
}

.btn-secondary:active,
.btn-secondary.active,
.open .dropdown-toggle.btn-secondary {
  background-image: none;
}

.btn-secondary.disabled,
.btn-secondary[disabled],
fieldset[disabled] .btn-secondary,
.btn-secondary.disabled:hover,
.btn-secondary[disabled]:hover,
fieldset[disabled] .btn-secondary:hover,
.btn-secondary.disabled:focus,
.btn-secondary[disabled]:focus,
fieldset[disabled] .btn-secondary:focus,
.btn-secondary.disabled:active,
.btn-secondary[disabled]:active,
fieldset[disabled] .btn-secondary:active,
.btn-secondary.disabled.active,
.btn-secondary[disabled].active,
fieldset[disabled] .btn-secondary.active {
  background-color: #aeaeae;
  background: -webkit-radial-gradient(
    #aeaeae 2%,
    #aeaeae
  ); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(
    #aeaeae 2%,
    #aeaeae
  ); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(
    #aeaeae 2%,
    #aeaeae
  ); /* For Firefox 3.6 to 15 */
  background: radial-gradient(
    #aeaeae 2%,
    #aeaeae
  ); /* Standard syntax (must be last) */
  border-color: #aeaeae;
}

.btn-secondary .badge {
  color: #0087c7;
  background-color: #ffffff;
}

.btn-outline-search {
  color: #0087c7;
  background-color: transparent;
  background-image: none;
  border-color: #0087c7;
  margin: 0 10px;
}

.btn-outline-search:hover {
  color: #ffffff;
  background-color: #0087c7;
  border-color: #0087c7;
}

.btn-outline-search:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-onlinetools {
  padding: 30px;
}

.btn-search {
  padding: 20px 0;
  font-size: 18pt;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  // border: 2px solid rgb(0, 255, 255);
}

.btn-dashboard-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // border: 2px solid purple;
}

#middle-content {
  // border: 2px solid green;
  padding: 0px;
  margin: 0px;
  margin-top: 20px;
}

.btn-dashboard {
  width: 30%;
  color: #ffffff;
  background: #0087c7; /* For browsers that do not support gradients */
  background: -webkit-radial-gradient(
    #5fa6d1 2%,
    #0087c7
  ); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(
    #5fa6d1 2%,
    #0087c7
  ); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(
    #5fa6d1 2%,
    #0087c7
  ); /* For Firefox 3.6 to 15 */
  background: radial-gradient(
    #5fa6d1 2%,
    #0087c7
  ); /* Standard syntax (must be last) */
  border-color: #0087c7;
  border-radius: 0;
  padding: 30px 10px;
}

.date-part {
  width: 100px;
}

.interpretive-service-btn {
  width: 200px;
}

.bottom-align {
  padding: 10px 3px;
}

@media (max-width: 992px) {
  .btn-dashboard-container {
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
  }

  .btn-dashboard {
    width: 100%;
    margin: 1em 0;
  }
}

@media (max-width: 575.98px) {
}

.btn-dashboard:hover,
.btn-dashboard:focus,
.btn-dashboard:active,
.btn-dashboard.active,
.open .dropdown-toggle.btn-dashboard {
  color: #ffffff;
  background: #7fc3e3;
  background: -webkit-radial-gradient(#7fc3e3, #7fc3e3); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(#7fc3e3, #7fc3e3); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(
    #7fc3e3,
    #7fc3e3
  ); /* For Firefox 3.6 to 15 */
  background: radial-gradient(
    #7fc3e3,
    #7fc3e3
  ); /* Standard syntax (must be last) */
  border-color: #7fc3e3;
}

.btn-dashboard:active,
.btn-dashboard.active,
.open .dropdown-toggle.btn-dashboard {
  background: #0087c7;
  background-image: none;
}

.btn-lvlfour {
  color: #ffffff;
  font-size: 12pt;
  background: #0087c7; /* For browsers that do not support gradients */
  background: -webkit-radial-gradient(
    #5fa6d1 2%,
    #0087c7
  ); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(
    #5fa6d1 2%,
    #0087c7
  ); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(
    #5fa6d1 2%,
    #0087c7
  ); /* For Firefox 3.6 to 15 */
  background: radial-gradient(
    #5fa6d1 2%,
    #0087c7
  ); /* Standard syntax (must be last) */
  border-color: #0087c7;
  border-radius: 0;
  padding: 10px 10px;
}

.btn-lvlfour:hover,
.btn-lvlfour:focus,
.btn-lvlfour:active,
.btn-lvlfour.active,
.open .dropdown-toggle.btn-lvlfour {
  color: #ffffff;
  background: #7fc3e3;
  background: -webkit-radial-gradient(#7fc3e3, #7fc3e3); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(#7fc3e3, #7fc3e3); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(
    #7fc3e3,
    #7fc3e3
  ); /* For Firefox 3.6 to 15 */
  background: radial-gradient(
    #7fc3e3,
    #7fc3e3
  ); /* Standard syntax (must be last) */
  border-color: #7fc3e3;
}

.btn-lvlfour:active,
.btn-lvlfour.active,
.open .dropdown-toggle.btn-lvlfour {
  background: #0087c7;
  background-image: none;
}

.btn-lvlfourmcal {
  color: #ffffff;
  background: #00365b; /* For browsers that do not support gradients */
  background: -webkit-radial-gradient(
    #49708f 2%,
    #00365b
  ); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(
    #49708f 2%,
    #00365b
  ); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(
    #49708f 2%,
    #00365b
  ); /* For Firefox 3.6 to 15 */
  background: radial-gradient(
    #49708f 2%,
    #00365b
  ); /* Standard syntax (must be last) */
  border-color: #00365b;
  border-radius: 0;
  padding: 10px 10px;
}

.btn-lvlfourmcal:hover,
.btn-lvlfourmcal:focus,
.btn-lvlfourmcal:active,
.btn-lvlfourmcal.active,
.open .dropdown-toggle.btn-lvlfourmcal {
  color: #ffffff;
  background: #0087c7;
  background: -webkit-radial-gradient(#0087c7, #0087c7); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(#0087c7, #0087c7); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(
    #0087c7,
    #0087c7
  ); /* For Firefox 3.6 to 15 */
  background: radial-gradient(
    #0087c7,
    #0087c7
  ); /* Standard syntax (must be last) */
  border-color: #0087c7;
}

.btn-lvlfourmcal:active,
.btn-lvlfourmcal.active,
.open .dropdown-toggle.btn-lvlfourmcal {
  background: #0087c7;
  background-image: none;
}
/* Carousel
---------------------------------------------------------------------------- */
.carousel {
  display: flex;
  margin-bottom: 10px;
}

// .carousel-item {
//   // border: 2px solid magenta;
// }

.carousel-item .img-fluid {
  width: 100%;
  height: 100%;
}

.carousel-caption {
  position: absolute;
  left: 60%;
  top: 35%;
  right: 10%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: left;
}

.carousel-caption h2,
.carousel-caption h3 {
  color: #ffffff;
  font-size: 36pt;
  text-shadow: 2px 2px 7px #333333;
}

.carousel-caption p {
  /*color: #7D2350;*/
  font-size: 25px;
}

.carousel-caption a {
  text-decoration: none;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

@media (max-width: 991px) {
  .carousel-caption {
    position: absolute;
    left: 60%;
    top: 25%;
    right: 10%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #ffffff;
    text-align: left;
  }

  .carousel-caption h2,
  .carousel-caption h3 {
    color: #ffffff;
    font-size: 21pt;
    text-shadow: 2px 2px 7px #333333;
  }
}

@media (max-width: 575.98px) {
  .carousel-caption {
    position: absolute;
    left: 50%;
    top: 0;
    right: 10%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #ffffff;
    text-align: left;
  }

  .carousel-caption h2,
  .carousel-caption h3 {
    color: #ffffff;
    font-size: 14pt;
    text-shadow: 2px 2px 7px #333333;
  }
}

/*
 * Three Category Section
 * --------------------------------------------------
 */
.category-section {
  background: #dadada;
  position: relative;
}
/*Card Section*/
.card-section {
  position: relative;
  padding-bottom: 130px;
}

.category-card {
  position: relative;
  margin-top: -70px;
  /*padding: 30px 25px;*/
  text-align: center;
  background: #f5faf8;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.category-card .icon,
.category-card .icon a {
  font-size: 70px;
  color: #00365b;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.category-card p {
  color: #6c6e7e;
  line-height: 30px;
  padding-bottom: 0;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.category-card-link {
  text-transform: uppercase;
  font-size: 21px;
  margin-bottom: 0;
  padding: 40px 25px;
  color: #2b1640;
  background-color: #f5faf8;
  display: block;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.category-card-link:hover {
  background: #007db8;
  color: #ffffff;
}

.category-card-link:hover,
.category-card p:hover,
.category-card .icon:hover,
.category-card .icon a:hover {
  color: #ffffff;
}

.category-card-icon {
  font-size: 72pt !important;
  line-height: 1.1 !important;
}

.card {
  background-color: #fff !important;
}

.card-pad20 {
  padding: 20px 0 0;
}

.card-text {
  padding-bottom: 20px;
}

.card-btn {
  background-color: #0071B9 !important;
  border-color: #0071B9 !important;
  border: 1px solid #0071B9;
  color: #fff;
}

.card-btn:hover {
  color: #0071B9;
  background-color: #fff !important;
}

.cta-wrapper .row {
  padding-bottom: 0 !important;
}

.cta-wrapper .col {
  padding: 0 12px 0 12px;
}

.cta-wrapper .card {
  border: none;
}

@media (max-width: 991px) {
  .card-section {
    position: relative;
    padding-bottom: 100px;
  }

  .category-card {
    position: relative;
    margin-top: -30px;
    padding: 30px 25px;
    text-align: center;
    background: #f5faf8;
    -webkit-box-shadow: 0 0 8px rgba(74, 74, 74, 0.1);
    box-shadow: 0 0 8px rgba(74, 74, 74, 0.1);
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }

  .category-card .icon {
    font-size: 60px;
    color: #00365b;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }

  .category-card-link {
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 0px;
    padding: 0px 15px;
    color: #2b1640;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
}

@media (max-width: 575.98px) {
  .card-section {
    position: relative;
    padding-bottom: 90px;
  }

  .category-card {
    position: relative;
    margin-top: 0;
    margin-bottom: 10px;
    padding: 0px;
    text-align: center;
    background: #f5faf8;
    -webkit-box-shadow: 0 0 8px rgba(74, 74, 74, 0.1);
    box-shadow: 0 0 8px rgba(74, 74, 74, 0.1);
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }

  .category-card-first {
    margin-top: -40px;
  }

  .category-card .icon {
    font-size: 40px;
    color: #00365b;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }

  .category-card-link {
    margin-bottom: 0px;
    padding: 20px 0;
    font-size: 18px;
    display: block;
  }
}

/*
 * Masthead Area
 * --------------------------------------------------
 */

.masthead_text_inner {
  text-align: center;
  position: relative;
  padding-top: 110px;
}

.masthead_text_inner h4 {
  color: #ffffff;
  font-size: 48px;
  font-weight: 500;
  padding-bottom: 10px;
}

.masthead_text_inner ul li {
  display: inline-block;
  margin-right: 15px;
}

.masthead_text_inner ul li a {
  color: #ffffff;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}

.masthead_text_inner ul li a i {
  position: relative;
  left: -10px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}

.masthead_text_inner ul li:hover a {
  color: #2bc0a4;
}

.masthead_text_inner h5 {
  font-size: 18px;
  color: #ffffff;
}
/*
 * Home Info and Social Media
 * --------------------------------------------------
 */

.media-body {
  padding: 0 20px;
}

.media-heading {
  font-size: 24px;
  color: #7d2350;
}

.homeinfo {
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
  margin-top: 20px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 20px 0;
  font-size: 12pt;
}

.homeinfo ul {
  list-style-type: none;
  margin-left: 0;
}

@media (max-width: 991px) {
  .homeinfo {
    border-top: 1px solid #dadada;
    border-bottom: 0;
    margin-top: 20px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 20px 0;
    font-size: 12pt;
  }
}

.homeinfo h3 {
  color: #00365b;
  font-weight: bold;
  margin-top: 5px;
}

.homeinfo h4 {
  color: #71758b;
  margin-bottom: 30px;
}

.homeinfospacer {
  margin-top: 30px;
}

@media (max-width: 1007px) {
  .homeinfospacer-narrow {
    margin-top: 30px;
  }
}

.homeinfo .primary {
  color: #00365b;
}

.homeinfo .csaddress {
  color: #00365b;
  font-weight: bold;
  margin-bottom: 0;
}

.social {
  margin: 0;
  font-size: 12pt;
}

@media (max-width: 991px) {
  .social {
    padding: 0 20px;
  }
}

// .social img {
// }

.social h4 {
  text-align: center;
}
/*
 * Level Four Page Row
 * --------------------------------------------------
 */

.lvlfour {
  margin-bottom: 50px;
}

.lvlfour-gutter {
  padding: 0 150px;
}

.navbar-lvlfour {
  background-color: transparent;
  border: none;
  color: #00365b;
  margin-bottom: 20px;
}

.nav-lvlfour li {
  margin-right: 12px;
}

.nav-lvlfour li:last-child {
  margin-right: 0;
}

@media (max-width: 1200px) {
  .lvlfour-gutter {
    padding: 0px 12px;
  }
}

@media (max-width: 991px) {
  .lvlfour {
    margin-bottom: 20px;
  }

  .lvlfour a {
    margin-bottom: 10px;
  }

  .lvlfour-gutter {
    padding: 0 12px;
  }

  .nav-lvlfour li {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
/*
 * Vertical Divider Columns
 * --------------------------------------------------
 */
.vdcols {
  padding-bottom: 100%;
  margin-bottom: -100%;
  overflow: hidden;
}

@media (max-width: 991px) {
  // .vdcol-md-6, .vdcol-md-4, .vdcol-md-3
  // {
  //   padding-left: 40px;
  //   padding-bottom: 20px;
  //   border-bottom: 0px solid #ddd;
  //   margin-bottom: 20px;
  // }

  .hdrow-md-12 {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 992px) {
  .hdrow-md-12 {
    padding-bottom: 20px;
    border-bottom: 1px solid #dddddd;
  }

  .vdcol-md-6:first-child,
  .vdcol-md-4:first-child,
  .vdcol-md-3:first-child {
    /*padding-left: 40px;*/
    border-bottom: 0px;
  }

  .vdcol-md-6:not(:first-child),
  .vdcol-md-4:not(:first-child),
  .vdcol-md-3:not(:first-child) {
    border-left: 1px solid #dddddd;
    border-bottom: 0px;
  }

  .vdcol-md-6:not(:last-child),
  .vdcol-md-4:not(:last-child),
  .vdcol-md-3:not(:last-child) {
    border-right: 1px solid #dddddd;
    margin-right: -1px;
    border-bottom: 0px;
  }
}

@media (max-width: 576px) {
  .hdrow-md-12 {
    margin-left: 10px;
    margin-right: 10px;
  }
}

/*
 * Show/Hide Search Area
 * --------------------------------------------------
 */

.search_area {
  position: fixed;
  top: -100px;
  left: 0px;
  width: 100%;
  z-index: 40;
  background: #ffffff;
  padding: 0px 110px;
  -webkit-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.search_area .search_inner {
  position: relative;
}

.search_area .search_inner:before {
  content: "";
  height: 34px;
  width: 4px;
  position: absolute;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #00365b;
}

.search_area .search_inner input[type="text"] {
  border: none;
  border-radius: 0px;
  padding: 0px 32px 0px 10px;
  width: 85%;
  height: 98px;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 36px;
  color: #b5aec4;
  line-height: 98px;
  font-weight: normal;
}

.search_area .search_inner input.placeholder {
  font-size: 36px;
  color: #b5aec4;
  line-height: 98px;
  font-weight: normal;
}

.search_area .search_inner input:-moz-placeholder {
  font-size: 36px;
  color: #b5aec4;
  line-height: 98px;
  font-weight: normal;
}

.search_area .search_inner input::-moz-placeholder {
  font-size: 36px;
  color: #b5aec4;
  line-height: 98px;
  font-weight: normal;
}

.search_area .search_inner input::-webkit-input-placeholder {
  font-size: 36px;
  color: #b5aec4;
  line-height: 98px;
  font-weight: normal;
}

.search_area .search_inner i {
  position: absolute;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #00365b;
  font-size: 30px;
  cursor: pointer;
}

.search_area.open {
  top: 0px;
}

@media (max-width: 991px) {
  .search_area {
    position: fixed;
    top: -100px;
    left: 0px;
    width: 100%;
    z-index: 40;
    background: #ffffff;
    padding: 0px 30px;
    -webkit-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
  }

  .search_area .search_inner input[type="text"] {
    border: none;
    border-radius: 0px;
    padding: 0px 20px;
    width: 65%;
    height: 98px;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 36px;
    color: #b5aec4;
    line-height: 98px;
    font-weight: normal;
  }
}
/*
 * Footer
 * --------------------------------------------------
 */

#footer-content {
  text-align: center;
  font-size: 12pt;
  padding: 20px 0;
  background: #f1f1f1;
}

@media only screen and (max-width: 600px) {
  #footer-content .nav-item {
    padding: 0 40px;
  }
}

.footer-copy {
  color: #747474;
  font-size: 11pt;
  padding-top: 12px;
}

.footer-img {
  padding-right: 20px;
  padding-bottom: 20px;
  margin-top: 5px;
}

/*
 * Card
 * --------------------------------------------------
 */

.card-title-bold {
  font-weight: bold;
}

.card-img-fix {
  overflow: hidden;
}

.card-noborder {
  border: 0px;
}

/*
 * Pagination
 * --------------------------------------------------
 */

.page-link {
  color: #0087c7;
}

.page-link:hover {
  color: #00365b;
}

.page-item.active .page-link {
  background-color: #0087c7;
  border-color: #0087c7;
}

/*
 * Progress Bar
 * --------------------------------------------------
 */

.progress-bar {
  background-color: #0071b9;
}

.bg-primary {
  background-color: #0071b9 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0087c7 !important;
}

.bg-secondary {
  background-color: #0087c7 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #00365b !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #0087c7 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #00365b !important;
}

/*
 * Application Page CSS
 * --------------------------------------------------
 */

// .form-group {
// }

.form-row-override {
  margin-right: 0;
  margin-left: 0;
}

.providername {
  color: #00365b;
  font-weight: bold;
}

.providerdetails {
  color: #0087c7;
}

.providerborder {
  border: 1px solid #dee2e6;
  padding-bottom: 10px;
  padding-top: 20px;
}

.btn-details {
  border: 1px solid #c0c0c0;
  padding: 12px 0;
  margin-bottom: 10px;
}

.btn-makepcp {
  border: 1px solid #ebebeb;
  padding: 10px 20px;
}

.btn-report {
  border: 1px solid #a04141;
  padding: 10px 20px;
}

@media (max-width: 991px) {
  .border {
    margin-bottom: 20px;
  }

  .btn-search {
    margin-bottom: 20px;
  }
}

/*
 * Leadership Page CSS
 * --------------------------------------------------
 */

.card-text-exec {
  color: #0087c7;
  font-size: 12pt;
}

.card-link-exec {
  font-size: 14pt;
}

/* ------   End September 2018 Off Canvas  ------------- */

/* -------------------------------------------------------------------------
 Utility Classes ONLY USED FOR STYLE GUIDE. DO NOT USE IN PRODUCTION.
---------------------------------------------------------------------------- */
.sg-container {
  overflow: hidden;
  padding: 16px 24px;
  /* For older browsers */
  padding: 1rem 1.5rem;
}

.sg-animated {
  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  -ms-transition: all 0.6s ease-out;
  -o-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
}

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  color: #00365b;
  font-size: 48pt;
}

.sg-h1,
.sg-h2,
.sg-h3 {
  border-bottom: 1px solid #ddd;
  font-weight: normal;
  margin-bottom: 1em;
  margin-top: 0;
}

.sg-h1 {
  font-weight: bold;
  padding-bottom: 0.2em;
  margin-top: 1em;
  margin-bottom: 1.25em;
  border-bottom-width: 3px;
}

.sg-h2 {
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
  text-transform: capitalize;
  margin-top: 1.5em;
  border-bottom-width: 2px;
}

.sg-h3 {
  padding-bottom: 0.25em;
  border-color: #eee;
}

.sg-about {
  margin-top: 2em;
}

.row > .page-header {
  color: #00365b;
  font-size: 48pt;
}

.sg-section {
  margin-bottom: 32px;
  /* For older browsers */
  margin-bottom: 3rem;
  overflow: hidden;
}

.sg-anchor {
  display: block;
  padding-top: 1em;
}

.sg-color {
  background: #fff;
  border: 1px solid #b3b3b3;
  display: inline-block;
  height: 80px;
  margin: 0 0.5em 0.5em 0;
  padding: 5px;
  position: relative;
  width: 100px;
}

.sg-lg {
  width: 140px;
  height: 100px;
}

.sg-color-swatch {
  display: block;
  height: 100%;
  width: 100%;
}

.sg-color-swatch span {
  background: #000;
  background: rgba(0, 0, 0, 0.7);
  bottom: 5px;
  color: #fff;
  font-size: 0.8em;
  margin: 0;
  opacity: 0;
  padding: 5px 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  width: 88px;
}

.sg-lg .sg-color-swatch span {
  width: 128px;
}

.sg-color-swatch:hover span {
  opacity: 1;
}

.sg-brand-primary .sg-color-swatch {
  background: #00365b;
}

.sg-brand-2 .sg-color-swatch {
  background: #7d2350;
}

.sg-brand-3 .sg-color-swatch {
  background: #f2f1f1;
}

.sg-brand-4 .sg-color-swatch {
  background: #0087c7;
}

.sg-brand-5 .sg-color-swatch {
  background: #666667;
}

.sg-brand-6 .sg-color-swatch {
  background: #71758b;
}

.sg-brand-7 .sg-color-swatch {
  background: #623393;
}

.sg-brand-8 .sg-color-swatch {
  background: #008579;
}

.sg-brand-9 .sg-color-swatch {
  background: #7fc3e3;
}

.sg-brand-success .sg-color-swatch {
  background: #469408;
}

.sg-brand-info .sg-color-swatch {
  background: #0087c7;
}

.sg-brand-warning .sg-color-swatch {
  background: #b30000;
}

.sg-brand-danger .sg-color-swatch {
  background: #d9831f;
}

.sg-font {
  font-size: 14pt;
  padding: 0 0 0.2em 0;
}

.sg-font-primary {
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.sg-top {
  text-align: right;
  border-bottom: 1px solid #ddd;
}

.sg-btn--top {
  font-size: 10pt;
  text-transform: uppercase;
  color: darkgray;
}

.show-grid {
  padding: 0.5em 0;
}

.show-grid div {
  background-color: #dddddd;
  border: 1px solid #808080;
  padding: 0.5em;
}

.bs-component {
  position: relative;
}

/* Module Padding
---------------------------------------------------------------------------- */
.form-group {
  margin-top: 20px;
}

.alert.alert-info {
  margin-top: 20px;
}
/* Call to Action
---------------------------------------------------------------------------- */
.calltoaction {
  position: relative;
  width: auto;
  padding: 15px 25px;
  border: 1px solid black;
  margin-top: 10px;
  margin-bottom: 10px;
}

.calltoaction > .row {
  display: flex;
  align-items: center;
}

.cta-block {
  display: block !important;
  text-align: center;
}

// .calltoaction > .row > [class^="col-"], .calltoaction > .row > [class*=" col-"] {
//     float: none;
//     display: table-cell;
//     vertical-align: middle;
// }

.cta-contents {
  padding-top: 10px;
  padding-bottom: 10px;
}

.cta-title {
  color: #0071b9;
  margin: 0 auto 15px;
  padding: 0;
}

.cta-desc p:last-child {
  margin-bottom: 0;
}

.cta-button {
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-block;
}

.calltoaction.calltoaction-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.calltoaction.calltoaction-primary {
  color: #0071b9;
  background-color: #e5f2f8 ;
  // border-color: #0071b9;
  border: none
}

.calltoaction.calltoaction-secondary {
  color: #0071b9;
  background-color: rgba(229, 242, 248, 1);
  border: none;
}

.calltoaction-secondary .cta-title {
  color: #0071b9 !important;
}

@media (max-width: 991px) {
  // .calltoaction.calltoaction-primary {

  //   border: 2px solid magenta;
  //   min-height: 200px;
  // }

  .cta-title {
    color: #fff;
    margin: 0px;
    padding: 0px;
  }

  .calltoaction {
    position: relative;
    padding: 0px;
    border: 1px solid black;
    margin-top: 10px;
    margin-bottom: 10px;
    // border: 2px solid magenta;
  }
}

@media (max-width: 600px) {
  // .calltoaction.calltoaction-primary {
  //   border: 2px solid teal;
  //   min-height: 200px;
  // }

  .calltoaction > .row {
    display: block;
    width: auto;
  }

  .calltoaction > .row > [class^="col-"],
  .calltoaction > .row > [class*=" col-"] {
    float: none;
    display: block;
    // vertical-align: middle;
    position: relative;
  }

  .cta-contents {
    text-align: center;
  }
}

.btn-cta {
  color: #ffffff;
  background-color: #0071b9;
  // background: radial-gradient(
  //   #49708f 2%,
  //   #00365b
  // ); 
  /* For browsers that do not support gradients */
  // border-color: #00365b;
  border-radius: 0.375rem;
  width: 90%;
  margin-left: 5%;
}

.btn-cta:hover,
.btn-cta:focus,
.btn-cta:active,
.btn-cta.active,
.open .dropdown-toggle.btn-cta {
  color: #ffffff;
  background: #7fc3e3;
  background: -webkit-radial-gradient(#7fc3e3, #7fc3e3); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(#7fc3e3, #7fc3e3); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(
    #7fc3e3,
    #7fc3e3
  ); /* For Firefox 3.6 to 15 */
  background: radial-gradient(
    #7fc3e3,
    #7fc3e3
  ); /* Standard syntax (must be last) */
  border-color: #7fc3e3;
  text-decoration: none;
}

.btn-cta:active,
.btn-cta.active,
.open .dropdown-toggle.btn-cta {
  background: #0087c7;
  background-image: none;
}
/* Form Controls
---------------------------------------------------------------------------- */
.form-control {
  display: block;
  width: 100%;
  height: 38px;
  margin-bottom: 10px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #808080;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #dddddd;
  border-radius: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-horizontal .control-label,
.form-horizontal .text-label,
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline .inputswitch-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 9px;
}

.form-horizontal .control-message {
  padding-top: 0;
}

.checkbox-inline,
.inputswitch-inline {
  color: #808080;
  font-size: 14px;
}

@media (min-width: 768px) {
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
    margin: 0 20px;
  }
}

@media (max-width: 1215px) and (min-width: 992px) {
  .control-message.control-label.pull-right {
    margin-right: 20px;
  }
}

@media (min-width: 1216px) {
  .control-message.control-label.pull-right {
    margin-right: 10px;
  }
}
/* General Inquiry form field box */
@media (min-width: 1200px) {
  .col-lg-8 {
    width: 970px;
  }
}

@media (min-width: 991px) {
  #general-inquiry-phone,
  #general-inquiry-email {
    width: 400px;
  }
}
/* Mobile Consent - Optimized the label and switch for the narrowest mobile viewport. */
@media (max-width: 991px) {
  .inputswitch-label {
    float: left;
    width: 295px;
    padding-left: 15px;
  }

  .inputswitch-switch {
    float: left;
    width: 70px;
    padding-top: 10px;
    padding-left: 15px;
  }
}

/* Desktop (3-column) Consent */
@media (min-width: 992px) {
  .inputswitch-label {
    float: left;
    width: 200px;
    padding-left: 15px;
  }

  .inputswitch-switch {
    float: right;
    width: 70px;
    padding-top: 20px;
  }
}

/* Modal
---------------------------------------------------------------------------- */
.modal {
  display: none;
  overflow: auto;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #999999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-clip: padding-box;
  outline: none;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000;
}

.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
}

.modal-backdrop.in {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  min-height: 16.42857143px;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
}

.modal-body {
  position: relative;
  padding: 20px;
}

.modal-footer {
  margin-top: 15px;
  padding: 19px 20px 20px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 140px auto;
  }

  .modal-content {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .modal-sm {
    width: 480px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}
/* -------------------------------------------------------------------------
 Prettyprint
---------------------------------------------------------------------------- */
.pln {
  color: #ffffff;
}
/* plain text */
@media screen {
  .str,
  .string {
    color: #e6db74;
  }
  /* string content */
  .kwd,
  .keyword {
    color: #66d9ef;
  }
  /* a keyword */
  .com,
  .comment {
    color: #75715e;
  }
  /* a comment */
  .typ {
    color: #a6e22e;
  }
  /* a type name */
  .lit,
  .number {
    color: #ae81ff;
  }
  /* a literal value */
  /* punctuation, lisp open bracket, lisp close bracket */
  /* .pun, .opn, .clo { color: #660 } */
  .tag {
    color: #f92672;
  }
  /* a markup tag name */
  .atn {
    color: #a6e22e;
  }
  /* a markup attribute name */
  .atv {
    color: #e6db74;
  }
  /* a markup attribute value */
  /*.dec, .var { color: #606 }*/
  /* a declaration; a variable name */
  .fun {
    color: #ff0000;
  }
  /* a function name */
}
/* Use higher contrast and text-weight for printable form. */
@media print, projection {
  .str {
    color: #006600;
  }

  .kwd {
    color: #006;
    font-weight: bold;
  }

  .com {
    color: #600;
    font-style: italic;
  }

  .typ {
    color: #404;
    font-weight: bold;
  }

  .lit {
    color: #004444;
  }

  .pun,
  .opn,
  .clo {
    color: #444400;
  }

  .tag {
    color: #006;
    font-weight: bold;
  }

  .atn {
    color: #440044;
  }

  .atv {
    color: #006600;
  }
}
/* Specify class=linenums on a pre to get line numbering */
ol.linenums {
  border-left: 1px solid #444;
  margin: 0 0 0 3.3em;
  /* IE indents via margin-left */
  padding: 0;
}

ol.linenums li {
  color: #bebec5;
  line-height: 1.5;
  padding-left: 0.8em;
}
/* -------------------------------------------------------------------------
 Legacy IE
---------------------------------------------------------------------------- */
/**
 * IE 7 Fixes
 */

/* End IE 7 Fixes */
/**
 * IE 6 Fixes
 */
pre {
  _white-space: pre;
  _white-space: pre-wrap;
  _word-wrap: break-word;
}
/* End IE 6 Fixes */

/*------------------------------
    Vertical/Horizontal Dividers
  -------------------------------*/

.row {
  overflow: hidden;
}

.vdcols {
  padding-bottom: 100%;
  margin-bottom: -100%;
  overflow: hidden;
}

.hdrow-md-12 {
  border-bottom: 1px solid #ddd;
}

// @media(max-width: 991px) {
//     .vdcol-md-4:not(:last-child),
//     .vdcol-md-6:not(:last-child) {
//         border-bottom: 1px solid green;
//     }
// }

@media (min-width: 992px) {
  .vdcol-md-4:not(:first-child),
  .vdcol-md-6:not(:first-child) {
    border-left: 1px solid #ddd;
  }

  // .vdcol-md-4:not(:last-child),
  // .vdcol-md-6:not(:last-child) {
  //     border-right: 1px solid #ddd;
  //     margin-right: -1px;
  // }
}

/* -------------------------------------------------
    White Space for Update and View Member Profile Pages
   -------------------------------------------------*/

/* -----------------
   Mobile Viewports
   -----------------*/
@media (max-width: 991px) {
  .vd-col-spacing {
    margin-top: 10px;
  }

  .col-sm-12,
  .col-md-12 > .col-md-4 {
    padding-bottom: 15px;
  }

  .vd-col-spacing,
  form > .col-md-12,
  app-update-member-profile > .container,
  .container > div,
  /*.col-md-12 > div {
    padding: 0px;
  }*/

  .hdrow-md-12 {
    margin-top: 30px;
    padding-bottom: 30px;
  }

  .row {
    padding-bottom: 10px;
  }
}
/* -----------------
   Desktop Viewports
   -----------------*/
@media (min-width: 992px) {
  .vd-col-spacing {
    margin-top: 30px;
  }

  .hdrow-md-12 {
    margin-top: 30px;
    padding-bottom: 30px;
  }

  .nohdrow-md-12 {
    padding-bottom: 30px;
  }

  .row {
    padding-bottom: 10px;
  }

  .div.a {
    cursor: pointer;
  }
}

/* -------------------------------------------------------------------------
 Update Member Profile
---------------------------------------------------------------------------- */

@media (max-width: 1200px) and (min-width: 992px) {
  .form-group > .row {
    margin-right: 0px;
  }
}

.text-right {
  padding-top: 10px;
  padding-bottom: 30px;
  padding-left: 0px;
}

.control-message {
  font-size: 14px;
}

/* -------------------------------------------------------------------------
 View Member Profile
---------------------------------------------------------------------------- */
#personal-information .bottom-align-text {
  margin-top: 0px;
  margin-bottom: 30px;
}

#user-account .bottom-align-text {
  margin-top: 22px;
}

.current-health-network-plan {
  margin-left: 0px;
}

.info-bottom-row {
  padding-top: 40px;
}

.info-language-box {
  margin-bottom: 20px;
  padding-top: 20px;
  padding-left: 40px;
  white-space: nowrap;
}

.info-box {
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 40px;
}

.info-personal-box {
  padding-top: 10px;
}

.closer-group-info-box {
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 40px;
}

.info-bottom-row-main-text {
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.contact-info-address-box {
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 40px;
}

.contact-info-row {
  padding-top: 15px;
  padding-left: 25px;
}

.contact-info-address-inline {
  display: inline-block;
  padding-bottom: 10px;
}

.contact-info-address-inline-padleft {
  display: inline-block;
  padding-left: 70px;
  padding-bottom: 10px;
}

.info-personal-body-text {
  padding-left: 40px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.pcp-info-box {
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: 40px;
}

.loader {
  position: absolute;
  width: 100%;
  color: black;
  z-index: 9999;
  height: 100%;
  top: -20;
  bottom: 0;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-info-address-box-padleft {
  display: inline-block;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-horizontal .control-label {
  text-align: left !important;
}

.input-counter-margin-right {
  margin-right: -15px !important;
}
/* Request ID Card */
@media (max-width: 991px) {
  .contact-info-city,
  .contact-info-state,
  .contact-info-zip {
    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: 40px;
  }
}

/* Print ID Card */
@media (max-width: 1000px) {
  .panel-body {
    padding: 0;
  }
}

.print-id-large-copy-margin {
  margin: 2rem;
}

.print-id-card-large-copy {
  /*margin-left: 15px;
  margin-right: 15px;*/
  font-size: 2rem;
}

.btn-print-id-card {
  min-width: 118px;
  margin: 1rem 2rem;
}

/* Terms of Use white-space */
@media (min-width: 1001px) {
  .terms-of-use-body {
    margin-bottom: 30px;
  }
}

@media (max-width: 1000px) {
  .btn-print-id-card {
    min-width: 90%;
    float: none;
    display: block;
  }
}
/* PrimeNG Overrides*/
.ui-widget-content {
  border: 1px solid #d5d5d5;
  background: #ffffff;
  color: #222222;
}

.ui-state-default {
  border: 1px solid #d6d6d6;
  background: #ffffff;
  color: #555555;
}

.ui-state-default a {
  color: #555555;
}

.ui-state-active {
  border-color: #bebebe;
  background: #d6d6d6;
  color: #212121;
}

.ui-state-active a {
  color: #212121;
}

/* InputSwitch */
.ui-inputswitch-on {
  background: #0087c7 !important;
  color: #ffffff !important;
}

.ui-inputswitch-onlabel {
  background: radial-gradient(#0087c7, #0087c7) !important;
  color: #ffffff !important;
  padding-left: 5px;
  padding-right: 5px;
}

.inputswitch-switchlabel {
  font-size: 14px;
  color: #808080;
}
/*
.info-main-content-section-top {
    padding-top:15px;
}
*/

.info-main-content-section-top-bottom {
  padding-top: 15px;
  padding-bottom: 15px;
}

.changepcp-messages-section-top-bottom {
  padding-top: 20px;
  padding-bottom: 10px;
}
/*
.info-main-content-section {
    padding-top: 15px;
    padding-bottom: 5px;
}
*/

.info-main-content-body {
  padding-bottom: 10px;
}

@media (max-width: 991px) {
  .info-main-content-section {
    /*padding-top: 20px;*/
    padding-bottom: 5px;
  }

  .info-main-content-section-top {
    padding-top: 5px !important;
  }

  .info-personal-box {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .info-language-box {
    margin-bottom: 10px;
    padding-top: 10px;
    padding-left: 40px;
  }

  .info-box {
    margin-bottom: 20px;
    padding-top: 5px;
    padding-bottom: 20px;
    padding-left: 40px;
  }

  .info-bottom-row {
    padding-top: 5px;
  }
}

.border {
  border: 1px solid #dee2e6 !important;
}

.form-row-override {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.last {
  margin-bottom: 20px;
}

div.list-group-item:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

div.list-group-item.active,
div.list-group-item:focus {
  background-color: #d9edf7;
  cursor: pointer;
  color: #333333;
  border-color: #31708f;
}

div.active.list-group-item,
div.active.list-group-item:hover,
div.active.list-group-item:focus {
  z-index: 2;
  background-color: #d9edf7;
  border-color: #31708f;
  color: #333333;
}

a.list-group-item:hover,
a.list-group-item:focus {
  text-decoration: none;
  background-color: #f5f5f5;
}

div.list-group-item.h3.a,
div.list-group-item.div.div.h3.a:hover,
div.list-group-item.div.div.h3.a:focus {
  z-index: 2;
  color: #ffffff;
  background-color: #bbbbbb;
  border-color: #bbbbbb;
}

.pagination {
  cursor: pointer;
}

.a {
  cursor: pointer;
}

.table-hover {
  background: #ffffff;
  width: 50%;
  margin: 0px auto;
  border: 1px;
}

.panel-success {
  border: 1px solid #469408 !important;
}

.panel-warning {
  border: 1px solid #b30000 !important;
}

.panel-danger {
  border: 1px solid #d9831f !important;
}

.table-hover > thead > tr > th,
.table > thead > tr > td,
.table-hover > tbody > tr > th,
.table-hover > tbody > tr > td,
.table-hover > tfoot > tr > th,
.table-hover > tfoot > tr > td {
  border: 1px solid;
}

.panel-body {
  padding: 15px;
}

@media (max-width: 662px) {
  .table-hover {
    background: #ffffff;
    width: 100%;
    margin: 0px auto;
    border: 1px solid;
  }

  .table-hover.tr {
    border: 1px solid;
  }

  .well-large {
    background-color: #fff;
    border: none;
  }

  .panel-success {
    margin: 19px;
  }

  .panel-warning {
    margin: 19px;
  }

  .panel-danger {
    margin: 19px;
  }
}

@media (max-width: 667px) {
  /* Styles */
  .healthappraisalbtns {
    display: block;
  }

  .healthappraisalbtn {
    width: 100%;
    margin: 10px 0 10px 0;
  }

  .healthappraisalposition {
    float: none;
  }

  .healthappraisalmargin {
    margin: 0 40px 0 40px;
  }

  .progress {
    height: 40px;
    // background-color: magenta;
  }

  .sv_qstn {
    // clear:initial;
    background-color: transparent;
    border-color: transparent;
    margin: 0;
    font-family: "Noto Sans", "Helvetica Neue", "Helvetica", "Arial",
      "sans-serif";
    font-weight: bold;
    font-size: 20pt;
    color: #71758b;
  }

  .sv_qcbc {
    font-size: 12pt;
  }

  .checkbox label span span {
    line-height: 20px;
  }
}

@media (min-width: 992px) {
  .address-fixed-box {
    position: relative;
    height: 665px;
    width: 100%;
  }

  .address-fixed-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 50px;
  }

  .email-phone-fixed-box {
    position: relative;
    height: 370px;
    width: 100%;
  }

  .email-phone-fixed-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 50px;
  }

  .email-phone-fixed-title {
    height: 50px;
  }

  .push-right-icons {
    text-align: right;
  }

  .no-margin-top-h3 {
    margin-top: 0px;
  }
}

@media (max-width: 991px) {
  .margin-well {
    padding: 10px !important;
  }
}

.link-underline {
  text-decoration: underline;
}

/*---------------------------------------------------------------------------- */
/* match previous styles from bs3
---------------------------------------------------------------------------- */
h1,
.h1 {
  font-size: 38px;
  color: #00365b;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h2,
.h2 {
  font-size: 30px;
  color: #0087c7;
}

h3,
.h3 {
  font-size: 24px;
  color: #7d2350;
}

h4,
.h4 {
  font-size: 21px;
}

h4,
.h4 span {
  font-size: 24px;
  color: #0087d2;
}

h5,
.h5 {
  font-size: 14px;
}

h6,
.h6 {
  font-size: 12px;
}

label {
  font-weight: 700;
}

.panel {
  margin-bottom: 20px;
  background-color: #ffffff;
  border: 1px solid #bbbbbb;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.panel-title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 16px;
  color: inherit;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.panel-group .panel {
  margin-bottom: 0;
  border-radius: 0;
  overflow: hidden;
}

.panel-default {
  border-color: #dddddd;
}

.panel-default > .panel-heading {
  color: #333333;
  background-color: #fcfcfc;
  border-color: #dddddd;
}

.panel-default > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #dddddd;
}

.panel-default > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #dddddd;
}

.panel-primary {
  border-color: #bbbbbb;
}

.panel-primary > .panel-heading {
  color: #ffffff;
  background-color: #00365b;
  border-color: #00365b;
}

.panel-primary > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #00365b;
}

.panel-primary > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #00365b;
}

.panel-success {
  border-color: #469408;
}

.panel-success > .panel-heading {
  color: #ffffff;
  background-color: #469408;
  border-color: #469408;
}

.panel-success > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #469408;
}

.panel-success > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #469408;
}

.panel-info {
  border-color: #0087c7;
}

.panel-info > .panel-heading {
  color: #ffffff;
  background-color: #0087c7;
  border-color: #0087c7;
}

.panel-info > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #0087c7;
}

.panel-info > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #0087c7;
}

.panel-warning {
  border-color: #b30000;
}

.panel-warning > .panel-heading {
  color: #ffffff;
  background-color: #b30000;
  border-color: #b30000;
}

.panel-warning > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #b30000;
}

.panel-warning > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #b30000;
}

.panel-danger {
  border-color: #d9831f;
}

.panel-danger > .panel-heading {
  color: #ffffff;
  background-color: #d9831f;
  border-color: #d9831f;
}

.panel-danger > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #d9831f;
}

.panel-danger > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #d9831f;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f4f4f4;
  border: 1px solid #e3e3e3;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15);
}

.well-lg {
  padding: 24px;
  border-radius: 0;
}

.well-sm {
  padding: 9px;
  border-radius: 0;
}

/*---------------------------------------------------------------------------- */
/* end match previous styles from bs3
---------------------------------------------------------------------------- */

/*---------------------------------------------------------------------------- */
/* overrides for header
---------------------------------------------------------------------------- */

.topnav-top {
  margin-top: 22px;
}
/*
.dropdown-item {
  font-size: small;
}
*/
/*---------------------------------------------------------------------------- */
/* end overrides for header
---------------------------------------------------------------------------- */

.input-message {
  margin-top: 8px;
  margin-bottom: 8px;
}

.user-main-registration {
  margin-top: 110px;
}

.user-form-body {
  padding-bottom: 0.5rem;
}

.user-footer {
  margin-top: 20px;
  margin-bottom: 20px;
}
#header-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: "Noto Sans";
  height: 65px;
}
#header-left-content {
  /* Lets left column shrink/grow to available width */
  flex: 1 1 auto;
  justify-content: center;
  // align: center;
  align-items: center;
  margin-left: 15px;
}

#header-center-content {
  /* Forces side columns to stay same width */
  flex: 0 0;
  white-space: nowrap;
  margin-right: 40px;
  font-size: 14px;
}

#header-right-content {
  /* Forces side columns to stay same width */
  flex: 0 0;
  white-space: nowrap;
  margin-right: 15px;
  font-size: 14px;
}
.header-info {
  padding: 2px 0 2px 0;
}
.header-hello {
  font-size: 18px;
  white-space: nowrap;
}
@media (min-width: 768px) {
  .user-header-heading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 18pt;
    font-weight: bold;
  }
}

@media (max-width: 767px) {
  .user-header-heading {
    text-align: center;
    font-size: 18pt;
    font-weight: bold;
  }
  #header-main-container {
    flex-direction: column;
    justify-content: left;
    height: auto;
    padding-bottom: 15px;
  }
  #header-left-content,
  #header-center-content,
  #header-right-content {
    width: 100%;
    margin-right: 0;
    margin-left: 15px;
  }
}

aw-wizard-navigation-bar.horizontal
  ul.steps-indicator
  li:not(:last-child):before {
  background-color: #e6e6e6;
  height: 8px !important;
}

aw-wizard-navigation-bar.horizontal ul.steps-indicator li.done:before {
  background-color: #0087c7 !important;
  height: 8px !important;
}

aw-wizard-navigation-bar.horizontal ul.steps-indicator li.current:before {
  background-color: #e6e6e6 !important;
  height: 8px !important;
}

aw-wizard-navigation-bar.horizontal
  ul.steps-indicator
  li.current
  .step-indicator {
  background-image: url("../assets/images/check_circle_blue.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 40px !important;
  width: 40px !important;
  background-size: 40px;
  background-color: transparent !important;
  top: -24px !important;
}

aw-wizard-navigation-bar.horizontal ul.steps-indicator li.done .step-indicator {
  background-image: url("../assets/images/check_circle_blue.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 40px !important;
  width: 40px !important;
  background-size: 40px;
  background-color: transparent !important;
  top: -24px !important;
}

aw-wizard-navigation-bar.horizontal
  ul.steps-indicator
  li.editing
  .step-indicator {
  background-image: url("../assets/images/check_circle_blue.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 40px !important;
  width: 40px !important;
  background-size: 40px;
  background-color: transparent !important;
  top: -24px !important;
}

aw-wizard-navigation-bar.horizontal ul.steps-indicator li .step-indicator {
  background-image: url("../assets/images/check_circle_grey.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 40px !important;
  width: 40px !important;
  background-size: 40px;
  background-color: transparent !important;
  top: -24px !important;
}

.middle-step-container {
  padding-top: 15px;
  padding-bottom: 15px;
}

.unbold-label {
  font-weight: normal;
}

.view-only-personal-representative {
  text-align: center;
}

.main-wrapper {
  width: 100%;
}

.login-main-wrapper {
  width: 100%;
  text-align: center;
}

.background-container {
  background-image: url("../assets/images/background.jpg");
  background-repeat: no-repeat;
  background-position-y: top;
  background-position-x: center;
  background-size: cover;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 180px;
}

.background-container-registration {
  background-image: url("../assets/images/u4.jpg");
  background-repeat: no-repeat;
  background-position-y: top;
  background-position-x: center;
  background-size: cover;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 180px;
}

.tab-box-container {
  width: 553px;
  height: 720px;
  margin: 0 auto;
  padding-top: 50px;
}

.tab-box-sub {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  overflow: auto;
}

.tab-box-sub2 {
  width: 100%;
  overflow: auto;
}

.tbox-color {
  background-color: rgba(255, 255, 255, 0.75);
}

.link-container-small {
  height: 75px;
}

.tab-title {
  font-size: 28px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #71889a;
}

.tab-title-intro {
  text-align: justify;
  font-size: 14px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #808080;
  padding-bottom: 20px;
}

.tab-box-button-container {
  height: 70px;
}

.tab-box-link-container {
  height: 85px;
}

.tab-box-progress-container {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-box-body {
  text-align: center;
}

.tab-button-active {
  width: 50%;
  height: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #71889a;
}

.tab-button-inactive {
  width: 50%;
  height: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #71889a;
  background-color: rgba(255, 255, 255, 0.25);
}

.tab-button-inactive:hover {
  text-decoration: underline;
}

.tab-link {
  width: 50%;
  height: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #335e7c;
  font-weight: bold;
}

.tab-link:hover {
  text-decoration: underline;
}

.tab-link-bottom-left {
  width: 50%;
  height: 100%;
  float: left;
  text-align: left;
  color: #335e7c;
  font-weight: bold;
  padding-top: 20px;
  cursor: pointer;
}

.tab-link-bottom-left:hover {
  text-decoration: underline;
}

.tab-link-bottom-right {
  width: 50%;
  height: 100%;
  float: right;
  text-align: right;
  color: #335e7c;
  font-weight: bold;
  padding-top: 20px;
  cursor: pointer;
}

.tab-link-bottom-right:hover {
  text-decoration: underline;
}

.override-input-text {
  height: 40px;
  border: 1px solid #ced4da;
  width: 400px;
  font-size: 1rem;
  padding: 0.375rem 0.75rem;
}

.override-input-container {
  width: 400px;
  margin: 0 auto;
}

.override-input-label {
  display: flex;
  flex-direction: row;
  width: 50%;
  text-align: left;
  margin-top: 30px;
  color: #71889a;
  font-size: 20px;
  font-weight: bold;
}

.override-input-option-container {
  display: flex;
  flex-direction: row;
  text-align: right;
  margin-top: 30px;
}

.override-input-option-link {
  text-decoration: none;
  color: #007db8;
  font-size: 14px;
}

.override-element-container {
  width: 400px;
  margin: 0 auto;
  margin-top: 30px;
  text-align: left;
}

.override-checkbox-label {
  color: #666666;
  font-size: 13px;
}

.override-login-button {
  width: 100%;
  height: 42px;
  background-color: #00365b;
  font-size: 16px;
  color: #ffffff;
  border: none;
}

.override-radio-container {
  width: 100%;
  padding: 15px 0 15px 0;
  text-align: left;
}

.login-msg {
  word-wrap: break-word;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 400px;
  text-align: left;
}

.user-tab-body {
  font-size: 14px;
  color: #808080;
}

.user-tab-body-bottom {
  padding-bottom: 40px;
}

@media (max-width: 1000px) {
  .login-msg {
    width: 300px;
  }

  .tab-box-container {
    width: 400px;
    height: 600px;
  }

  .tab-box-button-container {
    height: 63px;
  }

  .tab-box-link-container {
    height: 122px;
  }

  .override-input-text {
    width: 296px;
  }

  .override-input-container {
    width: 296px;
  }

  .override-element-container {
    width: 296px;
  }
}

@media (max-width: 661px) {
  .background-container {
    background-image: none;
  }

  .tab-box-container {
    max-width: 320px;
  }

  .tab-box-button-container {
    display: none;
  }

  .tab-box-progress-container {
    display: none;
  }

  .tab-box-link-container {
    height: auto;
    text-decoration: none;
  }

  .tab-link {
    float: none;
    height: 63px;
    display: flex;
    justify-content: left;
    text-decoration: none !important;
    width: 100%;
  }
}

/*
 * PROGRESS BARS & INDICATORS
 * --------------------------------------------------
 */
.progress-bar {
  padding-left: 20px;
  background-color: #0071b9;
}
/* BLUE Checks */
.progress-bar-left-inner-blue {
  height: 6px;
  width: 44px;
  background-color: #0087c7;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.progress-bar-right-inner-blue {
  height: 6px;
  width: 44px;
  background-color: #0087c7;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.progress-bar-separator-inner-blue {
  height: 6px;
  width: 100%;
  background-color: #0087c7;
}

.progress-bar-check-blue {
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0087c7;
  border: solid 2px #0078c7;
  width: 26px;
  height: 26px;
  color: #ffffff;
  font-size: 16px;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.progress-bar-check-blue:hover {
  font-size: 18px;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
/* END BLUE */
/* GRAY No Checks */
.progress-bar-left-inner-gray {
  height: 6px;
  width: 44px;
  background-color: #dddddd;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.progress-bar-right-inner-gray {
  height: 6px;
  width: 44px;
  background-color: #dddddd;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.progress-bar-separator-inner-gray {
  height: 6px;
  width: 100%;
  background-color: #dddddd;
  position: relative;
  z-index: 1;
}

.progress-bar-check-gray {
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dddddd;
  border: solid 2px #dddddd;
  width: 26px;
  height: 26px;
  color: #dddddd;
  font-size: 22px;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}
/* END GRAY */
/* STROKE Numerical */
.progress-bar-left-inner-stroke {
  height: 6px;
  width: 44px;
  background-color: #0087c7;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.progress-bar-right-inner-stroke {
  height: 6px;
  width: 44px;
  background-color: #0087c7;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.progress-bar-separator-inner-stroke {
  height: 6px;
  width: 100%;
  background-color: #0087c7;
}

.progress-bar-check-stroke {
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: solid 2px #0078c7;
  width: 26px;
  height: 26px;
  font-family: Helvetica;
  font-weight: bold;
  color: #0087c7;
  font-size: 22px;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.progress-bar-check-stroke:hover {
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background-color: #0078c7;
  color: #ffffff;
}
/* END STROKE */
/* LABEL */
.progress-bar-label-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  color: #000000;
  font-size: 14px;
  font-family: Helvetica;
}

.progress-bar-right-inner-label {
  height: 6px;
  width: 44px;
}

.progress-bar-left-inner-label {
  height: 6px;
  width: 44px;
}

.progress-bar-separator-inner-label {
  height: 6px;
  width: 100%;
}
/* END LABEL */
/* GLOBAL Progress Bar Classes */
.progress-bar-wrapper {
  text-align: center;
  width: 90%;
  /*  margin-top: 28px; */
}

.progress-bar-inner {
  margin: 0 auto;
  width: 100%;
  max-width: 620px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.progress-bar-left {
  width: 44px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-bar-right {
  width: 44px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-bar-separator {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-bar-check-wrapper {
  width: 26px;
  height: 26px;
}

/* END GLOBAL */
/* END PROGRESS BARS */

/* The container */
.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-weight: 100;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50% !important;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  top: 8px;
  left: 8px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: white;
}

.x-mark-red {
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  color: #c33e40;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.bcontainer-height-small {
  height: 550px;
}

.tcontainer-height-medium {
  padding-top: 10px;
  height: 350px;
}

.tcontainer-height-small {
  padding-top: 10px;
  height: 550px;
}

.wrapper-height-increase {
  height: 720px;
}

.height-reduced {
  height: 40px;
}

.height-reduced-byquarter {
  height: 75%;
}

.green-check-large {
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #469408;
  border: solid 2px #469408;
  width: 150px;
  height: 150px;
  color: #ffffff;
  font-size: 16px;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.container-password {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 10px;
  white-space: nowrap;
}

.container-password-item {
  margin-left: 15px;
  text-align: left;
}

.container-password-complexity {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 15px;
  margin-bottom: 10px;
  white-space: nowrap;
}

.container-password-criteria {
  margin-left: 15px;
  text-align: left;
}

.grey-circle {
  color: #dddddd;
}

.no-top-margin {
  margin-top: 0px;
}

.margin-15 {
  margin-top: 15px;
}

.no-top-padding {
  padding-top: 0px;
}

.height-720 {
  height: 720px;
}

.attestation-message {
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: italic;
  text-align: justify;
}

body .ui-widget {
  font-size: 19px;
  color: inherit;
}

body .ui-dialog .ui-dialog-content {
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-clip: padding-box;
  outline: none;
  padding-right: 22px;
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 16px;
}

body .ui-dialog .ui-dialog-titlebar {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #dee2e6;
  padding-top: 28px;
  padding-left: 22px;
  padding-bottom: 24px;
  min-height: 16.42857143px;
  background-color: #fff;
  font-weight: inherit;
  color: inherit;
}

body .ui-dialog .ui-dialog-footer {
  padding: 15px 15px 15px 15px;
  text-align: right;
  border-top: 1px solid #dee2e6;
  border-bottom: none;
  border-right: none;
  border-left: none;
}

body .ui-widget-overlay {
  background-color: #000000;
  opacity: 0.6;
  filter: alpha(opacity=60);
}

.modal-dialog-md {
  width: 480px;
  margin: 5px;
}

body .ui-dialog .ui-dialog-footer button {
  height: 37px;
  width: 124px;
}

.font-icon {
  color: #0087c7;
}

.progress-spinner-style {
  position: fixed;
  z-index: 999;
  height: 125px;
  width: 125px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.progress-spinner-style:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.set-zindex-high {
  z-index: 12000;
}

.group-container {
  padding: 20px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  border: 1px solid #dddddd;
}

.container-main {
  max-width: 1920px;
  min-width: 320px;
  margin: 20px auto 20px auto;
  padding: 0px 20px 0px 20px;
}

.row-striped:nth-of-type(odd) {
  background-color: #efefef;
}

.row-striped:nth-of-type(even) {
  background-color: #ffffff;
}

// sort icon

sortalticon {
  display: none;
}

sortamountupalticon::before {
  content: "\23F6";
}

sortamountdownicon::before {
  content: "\23F7";
}

sortamountupalticon,
sortamountdownicon {
  svg {
      display: none;
  }
}

sortamountupalticon::before,
sortamountdownicon::before {
  font-size: 1rem;
  margin-top: -10px;
  margin-left: 0;
  color: var(--blue3);
}



// Bootstrap 5 Dashboard 

/*

Member Portal Dash

Steve Villanueva - 06/06/2024

Mobile, SD, HD

LOB COLOR-SETS
lob_mc.css (Medi-Cal | Sapphire Blue)
lob_oc.css (One Care | Leaf Green)
lob_pc.css (Pace     | Plum Purple)

FOOTER
footer.css

FONT AWESOME
fontawesome/css/fontawesome.css
fontawesome/css/duotone.css

Table of Contents

- Carousel 
- Branding Colors
- Structural
- Navigation
- Accordion

*/

html
{
    min-width:430px;
}

body
{
          font-family: 'Noto Sans';
          min-width: 430px;
}

/* NOTO SANS ONLY!!! */

@font-face
{
  font-family: 'Noto Sans';
  src: url(./fonts/NotoSans-Regular.ttf);
}

@font-face
{
  font-family: 'Noto Sans Light';
  src: url(./fonts/NotoSans-Light.ttf);
}

@font-face
{
  font-family: 'Noto Sans Bold';
  src: url(./fonts/NotoSans-Bold.ttf);
}

/* Carousel */

.d-md-block
{
    display:block !important;
}

.carousel-inner
{
    max-height:300px;
}

.carousel-caption
{
    text-align:left;
    top:40px;
    right: 10%;
    bottom: 1.25rem;
    left: 520px;
    padding-top: 0;
    padding-bottom: 100px;
}

.carousel-title
{

    color:#fff;
    font-size:30px;
    text-shadow: 2px 2px rgba(0,0,0,0.15);
    text-transform: uppercase;
}

.carousel-text
{
    color:#fff;
    font-size:18px;
    text-shadow: 2px 2px rgba(0,0,0,0.15);
    margin-bottom:12px;
}

.carousel-button
{
    background: none;
    margin: 0;
    font-size: 18px;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    -o-transition: all .1s ease-out;
    transition: all .1s ease-out;
    cursor:pointer;
    border-radius:5px;
    padding: 0.375rem 0.75rem;
    background-color:rgba(255,255,255,0.3);
}

button.carousel-button:hover
{
    -webkit-transition: all .1s ease-in;
    -moz-transition: all .1s ease-in;
    -o-transition: all .1s ease-in;
    transition: all .1s ease-in;
}

@media (max-width: 1100px) {

    .carousel-caption
    {
        top:40px;
        left:360px;
    }

    .carousel-title
    {
        font-size:18px;
    }

    .carousel-text
    {
        font-size:16px;
    }

    .carousel-inner
    {
        max-height:240px;
    }

    .carousel-inner img
    {
        max-height:240px;
        margin-left:-40px;
    }
    
}

@media (max-width: 720px) {

    .carousel-caption
    {
        left:300px;
    }

    .carousel-title
    {
        font-size:18px;
    }

    .carousel-text
    {
        font-size:14px;
    }

    .carousel-button
    {
        font-size:16px;
    }

    .carousel-inner
    {
        max-height:240px;
    }

    .carousel-inner img
    {
        max-height:240px;
        margin-left:-100px;
    }
    
}

@media (max-width: 640px) {

    .carousel-caption
    {
        left:260px;
        top:20px;
    }

    .carousel-title
    {
        margin-bottom:20px;
    }

    .carousel-text
    {
        display:none;
    }

    .carousel-inner
    {
    }

    .carousel-inner img
    {
        margin-left:-140px;
    }
    
}

/* Branding Colors */

.bg-tangerine
{
    background-color:#F05023;
}

.bg-sky
{
    background-color:#00B2E2;
}

.bg-ruby
{
    background-color:#E7194F;
}

.bg-sunflower
{
    background-color:#F99C24;
}

.bg-sapphire
{
    background-color:#0071B9;
}

.bg-leaf
{
    background-color:#7BC24E;
}

.bg-plum
{
    background-color:#9B278F;
}

.bg-graphite
{
    background-color:#5B6770;
}

/* Structural */

.row-height-100, .col-height-100
{
    height:100%;
}

.member-wrapper
{
    height:100%;
    width:100%;
    float:left;
    text-align:left;
}

.member-fa-wrapper
{
    height:100%;
    float:left;
    font-size:32px;
    padding:10px 20px 0 0;
}

.member-name-wrapper
{
    height:100%;
    float:left;
}

.member-name
{
    font-size:26px;
    max-width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
}

.member-cin
{
    color:gray;
    padding-bottom:5px;
}

.member-profile-link
{
    font-size:14px;
}

.member-profile-details
{
    height:100%;
    float:left;
    font-size:14px;
}

.member-profile-data
{
    padding-bottom:8px;
}

.member-separator
{
    height:10px;
    width:100%;
    border-bottom:1px solid rgba(200,200,200,1);
    display:inline-block;
}

.card-accordion-wrapper 
{
    background-color:#fff;
}

.card-wrapper
{
    padding:40px 100px 40px 100px;
}

.cta-wrapper
{
    padding: 40px 100px 40px 100px;
}

.cta-mini-wrap
{
    display:flex;
    height:100%;
}

.cta-mini-left
{
    margin-right:20px;
}

.cta-mini-image
{
    height:auto;
    margin:10px 0 10px 10px;
}

.cta-icon-wrapper
{
    font-size:32px;
}

.card-cta-body {
  background-color: #e7f1ff !important;
}

@media (max-width:1400px) {
    .cta-mini-image
    {
        height:auto;
    }
}

.cta-mini-right
{
    flex-grow:1;
    margin-top:10px;
}

@media (max-width: 1250px) {

    .cta-mini-wrap
    {
        display:inline-block;
        text-align:center;
    }

    .cta-mini-left
    {
        margin-bottom:20px;
    }

}

.container-fluid img
{
    height:55px;
    padding-left: 12px;
}

.msg-wrapper
{
    padding: 40px 100px 40px 100px;
}

.msg-toggle
{
    text-align:right;
    padding-right:15px;
}


.col-md-4-left
{
    padding:10px;
    height:100%;
}

.col-md-4-right
{
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:60px;
}

.card
{
    border-radius: 10px;
    background-color: rgba(244,244,244,0.75);
    border:1px solid #e3e3e3;
    height:100%;
}

.card-cta-left 
{
    background-color:#fff;
    text-align:left;
    border-top-left-radius:80px;
    border-top-right-radius:10px;
    border-bottom-left-radius:10px;
    border-top-right-radius:10px;
    overflow:hidden;
    height:100%;
}

.card-cta-right
{
    background-color:#fff;
    text-align:left;
    border-radius:10px 10px 10px 80px;
    overflow:hidden;
    height:100%;
}

.card-paddit
{
    padding:10px;
}



/* Navigation */
#navbarSupportedContent ul,
#navbarSupportedContent li.nav-item a,
#navbarSupportedContent a.dropdown-item
{
    color:#2e8ac6 !important;
}

.fa-nav-lob
{
    font-size:24px;
}

.nav-msg
{
    margin-top:10px;
    margin-right:20px;
    z-index:102;
}

.msg-notifier
{
    padding-top:7px;
    border:none;
    background-color:rgba(255,255,255,0);
}

nav.bg-primary
{
    z-index:100;
    opacity:0.9;
    position:sticky;
    top:0;
}

.nav-item
{
    margin-left:20px;
}

.navbar-toggler:focus
{
    box-shadow: none;
}

.navbar .img-fluid {
  padding-left: 12px;
}

.dropdown-menu
{
    margin-left:-100px;
}

.dropdown-menu[data-bs-popper] {
  left: 50px;
}

@media (max-width: 1199px) {
    
    .dropdown-menu
    {
        margin-left:0;
    }

    .nav-link
    {
        text-align:right;
    }

}

nav.bg-primary
{
    border-bottom:1px solid rgba(200,200,200,1);
}

ul,li.nav-item a
{
    color:#2e8ac6;
}

a.dropdown-item
{
    color:#2e8ac6;
}

.display-column
{
    display:flex;
    flex-direction: column;
    flex-basis: 100%;
    flex:2;
}

.card-header
{
    text-align:left;
}

.card-title
{
    font-size:22px;
    font-weight:500;
    color:#000 !important;
}

.card-footer
{
    font-size:14px;
    text-align:left;
}

/* Accordion */

.accordion-button {
  align-items: center;
  width: 100%;
  padding: 0 10px 0 0;
  height:70px;
  color:#0071b9 !important;
}

.dot-unread {
  height: 12px;
  width: 12px;
  background-color: #0071b9; 
  border-radius: 50%;
  display: inline-block;
}

.dot-read {
    height: 12px;
    width: 12px;
    background-color: rgba(255,255,255,0);
    border-radius: 50%;
    display:inline-block;
}

.p-2 {
  padding: 0.3rem !important;
}

.accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.accordion-status-success
{
    height:100%;
    width:5px;
    background-color:#7BC24E;
}

.accordion-status-important
{
    height:100%;
    width:5px;
    background-color:#d9831f;
}

.accordion-status-general
{
    height:100%;
    width:5px;
    background-color:#0071b9;
}

.accordion-dot
{
    height:100%;
    padding: 15px 10px 0 10px;
    vertical-align: text-top;
}

.accordion-content-wrapper
{
    padding:0;
}

.accordion-subject-unread
{
    font-size:18px;
    font-weight:bold;
    color:#0071b9;
}

.accordion-subject-read
{
    font-size:18px;
    font-weight:normal;
    color:#0071b9;
}

.accordion-content-body
{
    font-size:16px;
    color:#999999;
    padding:3px 3px 3px 0;
}

.accordion-date
{
    font-size:14px;
    color:#999999;
    padding:0 20px 0 0;
    text-align:right;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
  color:#0071b9;
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  color:#0071b9;
}

.accordion-item {
  margin-bottom:5px;
}

.accordion-body {
    text-align:left;
}

.accordion-delete
{
    width:100%;
    height:auto;
    text-align:right;
    padding:0 20px 5px 0;
}

.accordion-delete a
{
    text-decoration:none !important;
    color:#0071B9;
}

.card-min
{
    min-height:190px;
}

@media (max-width: 1250px) {

    .accordion-button
    {
        height:50px;
    }

    .card-wrapper
    {
        padding:60px 20px 60px 20px;
    }

    .cta-wrapper
    {
        padding: 60px 20px 60px 20px;
    }

    .msg-wrapper
    {
        padding: 60px 20px 60px 20px;
    }
    /*
    .col-md-4, .col-md-4-left, .col-md-4-right
    {
        display:none;
    }
    */
    .col-md-8
    {
        width:100%;
    }

    .card-body
    {
        text-align:center;
    }

    .accordion-subject-unread, .accordion-subject-read
    {
        font-size:15px;
    }

    .accordion-content-body
    {
        display:none;
    }

}

/* EOF */













// sort icon

sortalticon {
  display: none;
}

sortamountupalticon::before {
  content: "\23F6";
}

sortamountdownicon::before {
  content: "\23F7";
}

sortamountupalticon,
sortamountdownicon {
  svg {
      display: none;
  }
}

sortamountupalticon::before,
sortamountdownicon::before {
  font-size: 1rem;
  margin-top: -10px;
  margin-left: 0;
  color: var(--blue3);
}

.custom-control {
  display: flex;
}

.custom-control-label {
  padding-left: 6px;
}

button {
  border-radius: 0.375rem;
}

#faqSurveyBtn {
  color: #0087c7;
  background: #fff !important;
}

#faqSurveyBtn:hover {
  color: #fff;
  background: #7fc3e3 !important;
  border-color: #fff !important;
}

.hist-data {
  padding: 0;
}