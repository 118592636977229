/* You can add global styles to this file, and also import other style files */

@import  "bootstrap/scss/bootstrap.scss";
// @import "bootstrap/dist/css/bootstrap.css";
@import  "primeng/resources/primeng.css";
@import  "primeng/resources/themes/nova/theme.css";
@import  "./css/caloptimaOverrides.scss";
@import  "./css/custom.css";
@import  "./css/lob_mc.css";

@import "./css/fontawesome.css";  // vs  "node_modules/font-awesome/scss/font-awesome.scss"
@import "./css/duotone.css";


